import React, { Component } from "react";
import "./Styles.css";
import Announce from "../Announce";
import { Footer, Header } from "Components";
import { FaCheckCircle } from "react-icons/fa";
import { Helmet } from "react-helmet";
const canonicalUrl = `https://idealni.com.br${window.location.pathname}${window.location.search}`;

class AnuncieAqui extends Component {
  render() {
    return (
      <div>
 <Helmet >
      <title>Ideal - Anunciar</title>
      <meta name="description" content="Vendemos ou alugamos seu imóvel de maneira rápida e descomplicada." />
      <meta name="keywords" content="testando,testando1" />
      <link rel="canonical" href={canonicalUrl} />
 <meta property="og:title" content="Ideal - Anuncie seu imóvel aqui" />
      <meta property="og:description" content="Vendemos ou alugamos seu imóvel de maneira rápida e descomplicada." />
      <meta
        property="og:image"
        content="https://thainaltnoivas.com.br/assets/img4-BZ75uQfN.png"
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="400" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content="website" />
    </Helmet>

        <Header />
        <div className="baseAnuncio">
          <h5>Anuncie seu imóvel aqui!</h5>
        </div>
        <div className="containerCentralizado">
          <div className="containerMainAnuncio">
            <div>
              <div>
                <h5 className="textAnuncio1">
                  Vendemos ou alugamos seu imóvel de maneira rápida e
                  descomplicada.
                </h5>
              </div>

              <div className="pointNumbers">
                <div>
                  <h5 className="textAnuncio1Point">01</h5>
                  <p className="textAnuncio2Point">
                    Preencha o formulário com os dados do seu imóvel.
                  </p>
                </div>
                <div>
                  <h5 className="textAnuncio1Point">02</h5>
                  <p className="textAnuncio2Point">
                    Seu contato e informações serão encaminhados para um
                    especialista da nossa equipe.
                  </p>
                </div>

                <div className="">
                  <h5 className="textAnuncio1Point">03</h5>
                  <p className="textAnuncio2Point">
                    Nosso especialista entrará em contato para agendar uma
                    visita de avaliação do seu imóvel e da documentação
                    necessária.
                  </p>
                </div>
              </div>

              <div className="pointNumbers">
                <h5 className="textAnuncio1Point2" style={{ bottom: 20 }}>
                  Benefícios para você
                </h5>

                <div></div>
                <ul className="listaAnuncios" style={{ marginTop: 15 }}>
                  <li className="textAnuncio2Point3">
                    <FaCheckCircle
                      style={{ marginRight: "10px", color: "#7e1c1e" }}
                    />{" "}
                    {/* Ícone antes do texto */}
                    Atendimento personalizado;
                  </li>
                  <li className="textAnuncio2Point3">
                    <FaCheckCircle
                      style={{ marginRight: "10px", color: "#7e1c1e" }}
                    />{" "}
                    {/* Ícone antes do texto */}
                    Vendas mais rápidas com segmentação eficiente;
                  </li>
                  <li className="textAnuncio2Point3">
                    <FaCheckCircle
                      style={{ marginRight: "10px", color: "#7e1c1e" }}
                    />{" "}
                    {/* Ícone antes do texto */}
                    Avaliação profissional do seu imóvel;
                  </li>
                  <li className="textAnuncio2Point3">
                    <FaCheckCircle
                      style={{ marginRight: "10px", color: "#7e1c1e" }}
                    />{" "}
                    {/* Ícone antes do texto */}
                    Suporte completo na documentação e fechamento.
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <Announce />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default AnuncieAqui;
