import React, { Component } from "react";
import _ from "lodash";
import axios from "axios";
import "./Styles.css";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import swal from 'sweetalert'
import request from "request";
import { Cellphone } from "Masks";

class Announce extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "sell",
      cities: [],
      districts: [],
      name: "",
      email: "",
      cellphone: "",
      city: "",
      district: "",
      street: "",
      number: "",
      complement: "",
      message: "",
      errors: {},
      buttonState: "anunciar" // Novo estado para o botão
    };
  }

  async componentDidMount() {
    const cities = await request("GET", "Imovel/RetornarCidadesDisponiveis");
    this.setState({ cities: cities.lista });
  }

  checkChange = (event) => {
    this.setState({ type: event.target.value });
  };

  handleChange = (event, index) => {
    this.setState({ [index]: event.target.value });
  };

  handleChangeMask = (text, index) => {
    this.setState({ [index]: text });
  };

  changeCity = async (event) => {
    this.setState({
      city: event.target.value,
      cityName: _.find(this.state.cities, { codigo: event.target.value }).nome,
    });
    let districts = await request("GET", "Imovel/RetornarBairrosDisponiveis", {
      codigoCidade: event.target.value,
    });
    this.setState({ district: "", districts: districts.lista });
  };

  validateFields = () => {
    const errors = {};
    const requiredFields = [
      "name",
      "email",
      "cellphone",
      "city",
      "district",
      "street",
      "number"
    ];
    
    requiredFields.forEach(field => {
      if (!this.state[field]) {
        errors[field] = "Este campo é obrigatório";
      }
    });

    this.setState({ errors });
    
    return Object.keys(errors).length === 0; 
  };

  send = async () => {
    if (!this.validateFields()) {
      return;
    }

    this.setState({ buttonState: "carregando" }); 

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/email/newImovel`, {
        type: this.state.type,
        name: this.state.name,
        email: this.state.email,
        tellphone: "0000000",
        cellphone: this.state.cellphone,
        city: this.state.cityName,
        district: this.state.district,
        street: this.state.street,
        number: this.state.number,
        complement: this.state.complement,
        message: this.state.message,
      });

      this.setState({ buttonState: "anunciado" }); 
      swal({
        icon: 'success',
        title: 'Imóvel indicado com sucesso!',
        timer: 1500,
      });
    } catch (error) {
      this.setState({ buttonState: "anunciado" }); 
      swal({
        icon: 'error',
        title: 'Erro ao enviar o anúncio!',
        text: error.response ? error.response.data.message : 'Ocorreu um erro inesperado.',
      });
    }
  };

  render() {
    const { errors, buttonState } = this.state;
    return (
      <div className="teste22">
        <h2>Cadastre seu Imóvel</h2>
        <p>Preencha os dados abaixo que entraremos em contato.</p>
        <div style={{ padding: 20 }}>
          <RadioGroup
            row
            aria-label="type"
            name="type"
            value={this.state.type}
            onChange={this.checkChange}
            style={{ marginBottom: '20px' }} 
          >
            <FormControlLabel
              value="sell"
              control={<Radio color="primary" />}
              label="Quero vender meu imóvel"
            />
            <FormControlLabel
              value="rent"
              control={<Radio color="primary" />}
              label="Quero alugar meu imóvel"
            />
          </RadioGroup>
          <TextField
            variant="outlined"
            id="name"
            label="Nome completo"
            value={this.state.name}
            onChange={(e) => this.handleChange(e, "name")}
            fullWidth
            style={{ marginBottom: '20px' }} 
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            variant="outlined"
            id="email"
            label="E-mail"
            value={this.state.email}
            onChange={(e) => this.handleChange(e, "email")}
            fullWidth
            type="email"
            style={{ marginBottom: '20px' }} 
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            variant="outlined"
            id="cellphone"
            label="Celular"
            value={this.state.cellphone}
            onChange={(e) => this.handleChange(e, "cellphone")}
            InputProps={{ inputComponent: Cellphone.default }}
            fullWidth
            style={{ marginBottom: '20px' }} 
            error={!!errors.cellphone}
            helperText={errors.cellphone}
          />
          <TextField
            select
            variant="outlined"
            value={this.state.city}
            input={<OutlinedInput name={this.props.id} id={this.props.id} />}
            label="Cidade"
            onChange={this.changeCity}
            fullWidth
            style={{ marginBottom: '20px' }} 
            error={!!errors.city}
            helperText={errors.city}
          >
            {this.state.cities.map((city) => (
              <MenuItem key={city.codigo} value={city.codigo}>
                {city.nome} - {city.estado}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            variant="outlined"
            value={this.state.district}
            input={<OutlinedInput name={this.props.id} id={this.props.id} />}
            label="Bairro"
            onChange={(event) =>
              this.setState({ district: event.target.value })
            }
            fullWidth
            style={{ marginBottom: '20px' }} 
            error={!!errors.district}
            helperText={errors.district}
          >
            {this.state.districts.map((district) => (
              <MenuItem key={district.codigo} value={district.nome}>
                {district.nome}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            variant="outlined"
            id="street"
            label="Logradouro"
            value={this.state.street}
            onChange={(e) => this.handleChange(e, "street")}
            fullWidth
            style={{ marginBottom: '20px' }} 
            error={!!errors.street}
            helperText={errors.street}
          />
          <TextField
            type="number"
            variant="outlined"
            id="number"
            label="Número"
            value={this.state.number}
            onChange={(e) => this.handleChange(e, "number")}
            fullWidth
            style={{ marginBottom: '20px' }} 
            error={!!errors.number}
            helperText={errors.number}
          />
          <TextField
            variant="outlined"
            id="complement"
            label="Complemento"
            value={this.state.complement}
            onChange={(e) => this.handleChange(e, "complement")}
            fullWidth
            style={{ marginBottom: '20px' }} 
          />
          <TextField
            variant="outlined"
            id="message"
            label="Mensagem"
            value={this.state.message}
            onChange={(e) => this.handleChange(e, "message")}
            fullWidth
            multiline
            rows={4}
            style={{ marginBottom: '20px' }} 
          />
          <div style={{ marginTop: 20 }}>
            <button
              onClick={this.send}
              color="primary"
              variant="contained"
              className="buttonsAnuncio"
              disabled={buttonState === "carregando"} // Desabilita o botão durante o carregamento
            >
              {buttonState === "carregando" ? "Carregando..." : "Anunciar"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Announce;
