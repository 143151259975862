import React, { useState } from "react";
import dataItensChoose from "./mock/dataItensChoose";
import { FaImage, FaMap, FaPlay, FaRoad } from "react-icons/fa";
import "./styles.css";

const ItensChoose = ({ onClick, property }) => {
  const [itensDetail, setItensDetail] = useState(dataItensChoose);

  const handleClick = (index) => {
    const updatedItems = itensDetail.map((item, i) => {
      if (i === index) {
        return { ...item, active: true };
      } else {
        return { ...item, active: false };
      }
    });
    setItensDetail(updatedItems);

    onClick(itensDetail[index].type);
  };

  const shouldRenderItem = (item) => {
    if (item.type === "video" && property.urlvideo === "") {
      return false;
    }
    if (property.finalidade === "Aluguel") {
      return true;
    } else {
      return item.type !== "map";
    }
  };

  const renderIcon = (type, active, property) => {
    switch (type) {
      case "foto":
        return <FaImage className={active ? "iconActive" : "iconInactive"} />;
      case "video":
        return property.urlvideo !== ""
          ? <FaPlay className={active ? "iconActive" : "iconInactive"} />
          : null;
      case "map":
        return <FaMap className={active ? "iconActive" : "iconInactive"} />;
      // case "street":
      //   return <FaRoad className={active ? "iconActive" : "iconInactive"} />;
      default:
        return null;
    }
  };

  return (
    <div className="itensDisplay">
      {itensDetail.map((item, index) => (
        <div key={index} onClick={() => handleClick(index)}>
          {shouldRenderItem(item) && (
            <div className={`itemContainer ${item.active ? "active" : ""}`}>
              <span className={`bola ${item.active ? "active" : ""}`}>
                {renderIcon(item.type, item.active, property)}
              </span>
              <div className="tituloItem">
                <p>{item.title}</p>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ItensChoose;
