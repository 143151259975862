import React, { useCallback, useEffect, useState } from "react";
import "./styles.css";
import { getNumberFromValue, parseMoney } from "helpers/utils";
import Info from "Components/Info";
import { Divider, Button } from "@material-ui/core";
import ScrollAnimation from "react-animate-on-scroll";
import { FiHeart, FiShare2 } from "react-icons/fi";
import { FaHeart } from "react-icons/fa";
import { ScheduleModal } from "./ScheduleModal";
import StaticExample from "../globalModalWpp";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import request from "request";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
// import "bootstrap/dist/css/bootstrap.min.css";

export const PriceMenu = ({
  property,
  addFavorite,
  favoritesList,
  handleShare,
}) => {
  const [aberto, setAberto] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const notify = () =>
    toast("Obrigado pelo contato. Responderemos em breve!", {
      position: "top-center",
      autoClose: 5000,
      type: "success",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const campoObrigato = () =>
    toast("Ops! Houve um erro interno. Tente novamente", {
      position: "top-center",
      autoClose: 5000,
      type: "error",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const getTotalValue = () => {
    const valorcondominio = getNumberFromValue(property.valorcondominio);
    const valor = getNumberFromValue(property.valor);
    const valorIncendio = getNumberFromValue(
      property.valorparcelaseguroincendio
    );
    const valoriptu = getNumberFromValue(property.valoriptu) / 12;
    const valorIptuBruto = getNumberFromValue(property.valoriptu);

    return `${parseMoney(valorcondominio + valor + valoriptu + valorIncendio)}`;
  };

  const Modal = ({ isOpen, children }) => {
    useEffect(() => {
      const handleOutsideClick = (e) => {
        if (e.target.classList.contains("modal-overlay")) {
          closeModal();
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);

      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, [closeModal]);

    if (!isOpen) {
      return null;
    }

    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-content">{children}</div>
        </div>
      </div>
    );
  };

  const goToWpp = () => {
    const message =
      "Olá! gostaria de saber mais sobre esse imóvel: " + window.location.href;
    window.open(
      `https://api.whatsapp.com/send?phone=5531989531549&text=${message}`,
      "_blank"
    );
  };

  const rows = [
    {
      label: "IPTU",
      value:
        property.finalidade === "Aluguel"
          ? `${parseMoney(getNumberFromValue(property.valoriptu) / 12)}`
          : parseMoney(getNumberFromValue(property.valoriptu)),
      info: "O IPTU é um imposto municipal cujo valor é determinado pela prefeitura.",
    },
    {
      label: "Condomínio",
      value: property.valorcondominio,
      info: "Valor sujeito a alteração conforme deliberações condominiais, podendo, ainda, variar de acordo com as despesas fixas e eventuais do condomínio (ex.: água, luz, conservação e manutenção do prédio, entre outros).",
    },
    property.finalidade === "Aluguel"
      ? {
          label: "Seguro incêndio",
          value: property.valorparcelaseguroincendio,
          info: "Inclui proteção obrigatória contra incêndio, queda de raio ou explosão. O imóvel fica protegido e o proprietário é indenizado em casos de acidentes. 90% cobre o imóvel e 10% cobre os bens materiais do locatário.",
        }
      : {},
  ];

  const handleOpenMo = () => {
    setAberto(true);
  };

  const send = useCallback(async (data) => {
    const hours = await request("POST", "/Lead/IncluirLead", {
      nome: data.nome.value,
      email: data.email.value,
      telefone: data.celular.value,
      assunto: property.finalidade === "1" ? "ALUGUEL" : "VENDA",
      midia: "SITE",
      codigo: property.codigo,
      finalidade: property.finalidade,
      anotacoes: `${
        data.duvida.value
          ? data.duvida.value
          : "Olá, gostaria de ter mais informações deste imóvel. Aguardo seu contato, obrigado."
      } - CONTATO POR: ${data.whatsapp.value}`,
    });
  }, []);

  const [camposObrigatoriosComErro, setCamposObrigatoriosComErro] = useState(
    []
  );

  const validationFormContact = yup.object().shape({
    nome: yup
      .string()
      .required("O nome é obrigatorio")
      .max(50, "Máximo de caracteres permitidos é 50"),
    email: yup
      .string()
      .required("O e-mail é obrigatorio")
      .max(50, "Máximo de caracteres permitidos é 50"),
    celular: yup
      .string()
      .required("O numero de celular ou telefone é obrigatorio")
      .max(11, "O máximo de caracteres é 11"),
    assunto: yup
      .string()
      .required("O campo de assunto é obrigatorio")
      .max(100, "Máximo de caracteres permitidos é 100"),
    duvida: yup.string().max(500, "Máximo de caracteres permitidos é 500"),
    whatsapp: yup.string(),
  });

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    reset();
    setModalOpen(false);
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    e.target.reset();
    handleClick();
  };

  function handleClick(data) {
    send(data);
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationFormContact),
  });

  const handleSubmitAsync = async (e) => {
    e.preventDefault();
    setCamposObrigatoriosComErro([]);

    const data = e.target.elements;

    try {
      const camposObrigatorios = ["nome", "email", "celular"];
      const camposComErro = camposObrigatorios.filter(
        (campo) => !data[campo].value.trim()
      );

      if (camposComErro.length > 0) {
        setCamposObrigatoriosComErro(camposComErro);
        campoObrigato();
        return;
      }

      await send(data);
      reset();
      notify();
      setModalOpen(false);
    } catch (error) {
      campoObrigato();
      console.error(error);
    }
  };

  const replaceAddress = (endereco) => {
    endereco = endereco.replace(/ /g, "%20");
    endereco = endereco.replace(/-/g, "%20");
    endereco = endereco.replace(/,/g, "%20");
    return endereco;
  };

  return (
    <aside className="price-menu-container">
      <Modal isOpen={isModalOpen}>
      <h2 style={{fontSize: '30px'}}>Como podemos te ajudar?</h2>
        <h5>Saiba mais sobre este imóvel</h5>
        <p>Código: {property?.codigo}</p>
        <div>
          <form onSubmit={(e) => handleSubmitAsync(e)} className="formularios2">
            <div className="divForm">
              <p
                style={{
                  textAlign: "start",
                  margin: 0,
                  marginBottom: 10,
                  marginLeft: 12,
                }}
              >
                Nome
              </p>
              <input
                required
                type="text"
                name="nome"
                {...register("nome")}
                className={`inputCustom ${errors.nome ? "inputError" : ""}`}
                placeholder="Seu nome"
              />
              <p className="error-message">{errors.nome?.message}</p>
            </div>
            <div className="form-group divForm">
              <p
                style={{
                  textAlign: "start",
                  margin: 0,
                  marginBottom: 10,
                  marginLeft: 12,
                }}
              >
                Email
              </p>
              <input
                required
                type="email"
                name="email"
                {...register("email")}
                className="inputCustom"
                placeholder="Endereço de e-mail"
              />
              <p className="error-message">{errors.email?.message}</p>
            </div>

            <div className=" divForm">
              <p
                style={{
                  textAlign: "start",
                  margin: 0,
                  marginBottom: 10,
                  marginLeft: 12,
                }}
              >
                Telefone/Celular
              </p>
              <input
                required
                type="text"
                name="celular"
                {...register("celular")}
                className="inputCustom"
                placeholder="Telefone/Celular"
              />
              <p className="error-message">{errors.celular?.message}</p>
            </div>
            <div className="form-group divForm">
              <p
                style={{
                  textAlign: "start",
                  margin: 0,
                  marginBottom: 10,
                  marginLeft: 12,
                }}
              >
                Dúvida
              </p>
              <input
                type="text"
                name="duvida"
                id="duvida"
                {...register("duvida")}
                className="inputCustom"
                placeholder="Escreva sua mensagem"
              />
              <p className="error-message">{errors.mesg?.message}</p>
            </div>
            <div>
              <p>Como você prefere conversar?</p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ marginRight: 20 }}>
                  <input
                    type="radio"
                    id="huey"
                    name="drone"
                    value="WhatsApp"
                    checked
                    {...register("whatsapp")}
                  />
                  <label for="huey">WhatsApp</label>
                </div>

                <div>
                  <input
                    type="radio"
                    id="dewey"
                    name="drone"
                    value="Ligação"
                    {...register("whatsapp")}
                  />
                  <label for="dewey">Ligação Telefônica</label>
                </div>
              </div>
            </div>
            {camposObrigatoriosComErro.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  margin: 0,
                }}
              >
                <p style={{ color: "red" }}>
                  Preencha todos os campos obrigatório
                </p>
              </div>
            ) : null}

            <div style={{ display: "flex" }}>
              <button className="botao" style={{ marginRight: 20 }}>
                Entre em Contato
              </button>
              <button className="botaoFechar" onClick={closeModal}>
                Fechar
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {aberto && <StaticExample openModal={aberto}></StaticExample>}
      <ScrollAnimation animateIn="bounceInRight" animateOnce={true}>
        <div className="price-menu-content">
          <div className="price-menu-info-container">
            <div className="price-menu-info-content">
              <strong className="price-menu-label" style={{ marginBottom: 10 }}>
                {property?.finalidade === "Aluguel"
                  ? "Aluguel"
                  : "Valor do Imóvel"}
              </strong>
              <strong className="price-menu-price">{property.valor}</strong>
            </div>

            {rows.map((item) => (
              <div
                style={{ marginTop: 15 }}
                key={item.label}
                className="price-menu-info-content"
              >
                <span className="price-menu-label">
                  {item.label}
                  {item?.info && <Info text={item.info} />}
                </span>
                <span className="price-menu-price">{item.value}</span>
              </div>
            ))}
            {property?.finalidade === "Aluguel" && (
              <>
                <Divider style={{ margin: "10px 0" }} />
                <div className="price-menu-info-content">
                  <strong className="price-menu-label">Total</strong>
                  <strong className="price-menu-price">
                    {getTotalValue()}
                  </strong>
                </div>
              </>
            )}

            <ScheduleModal
              code={property.codigo}
              finalidade={property.finalidade}
            />

            <Button
              onClick={openModal}
              style={property.finalidade === "Aluguel" ? { marginTop: 30 } : {}}
              className="price-menu-button botaoNovo wpp"
            >
              Falar com especialista
            </Button>

            <div className="price-menu-2-buttons">
              <Button
                onClick={() => {
                  addFavorite(property);
                }}
              >
                {Array.from(favoritesList).includes(`${property?.codigo}`) ? (
                  <FaHeart
                    size={22}
                    color="#570605"
                    style={{ marginRight: 10 }}
                  />
                ) : (
                  <FiHeart
                    size={22}
                    color="#1c1c1c"
                    style={{ marginRight: 10 }}
                  />
                )}
                Favoritar
              </Button>
              <Button onClick={handleShare}>
                <FiShare2
                  size={22}
                  style={{ marginRight: 10 }}
                />
                Compartilhar
              </Button>
            </div>
          </div>
        </div>
        <iframe
          style={{ marginTop: 20, marginBottom: 30 }}
          width="99%"
          height="200"
          id="gmap_canvas"
          src={`https://maps.google.com/maps?q=${replaceAddress(
            `${
              property.finalidade === "Alugar"
                ? `${property.endereco}`
                : `${property.bairro}`
            }, ${property.cidade}-${property.estado}`
          )}&t=&z=15&radius=5&ie=UTF8&iwloc=&output=embed`}
          frameBorder="0"
          scrolling="no"
          title="geolocalizacao"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
      </ScrollAnimation>
    </aside>
  );
};
