import React, { Component } from 'react'

import './Capture.css'

import {
  Button,
  Paper,
  TextField,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Radio,
  OutlinedInput,
  Snackbar,
} from '@material-ui/core'

import { Cellphone } from 'Masks'

import request from 'request'

class Capture extends Component {
  constructor(props) {
    super(props)
    this.state = {
      announce: 2,
      types: [],
      districts: [],
      count: '',
      open: '',
      type: '',
      name: '',
      email: '',
      street: '',
      tellphone: '',
      message: '',
      countResult: '',
    }
  }

  getRandom = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  async componentDidMount() {
    const first = this.getRandom(1, 20),
      second = this.getRandom(1, 20)

    const types = await request('GET', 'Imovel/RetornarTiposImoveisDisponiveis')

    this.setState({ count: `${first} + ${second}`, types: types.lista })
  }

  handleChange = (event, index) => {
    this.setState({ [index]: event.target.value })
  }

  handleClose = (message = '') => {
    this.setState({ open: message })
  }

  checkChange = (event) => {
    this.setState({ announce: parseInt(event.target.value) })
  }

  send = async () => {
    try {
      // eslint-disable-next-line
      if (parseInt(this.state.countResult) !== eval(this.state.count))
        this.handleClose('Informe a soma corretamente')
      else {
        await request('POST', 'Lead/IncluirLead', {
          nome: this.state.name,
          telefone: this.state.tellphone,
          email: this.state.email,
          finalidade: this.state.announce,
          midia: 'Site Ideal',
          anotacoes: `Endereço: ${this.state.street}, Tipo: ${this.state.type}, Observação: ${this.state.message}`,
        })

        this.handleClose('Imóvel enviado com sucesso!')
      }
    } catch (ex) {
      this.handleClose('Informe a soma corretamente')
    }
  }

  render() {
    return (
      <section className='capture'>
        <section className='row initial'>
          <div className='column'>
            <h1>Que tal anunciar seu imóvel hoje? </h1>
            <h2>Nos conte um pouco mais sobre o imóvel disponível</h2>
            <img
              src='empresario.png'
              alt='empresario'
              className='worker-image'
            />
            <h2>
              Em breve, entraremos em contato para uma consultoria, sem nenhuma
              avaliação nem custo
            </h2>
          </div>
          <div className='column'>
            <Paper>
              <TextField
                variant='outlined'
                autoFocus
                id='name'
                label='Nome'
                value={this.state.name}
                onChange={(e) => this.handleChange(e, 'name')}
                fullWidth
              />
              <TextField
                variant='outlined'
                id='email'
                label='E-mail'
                value={this.state.email}
                onChange={(e) => this.handleChange(e, 'email')}
                fullWidth
                type='email'
              />
              <TextField
                variant='outlined'
                id='tellphone'
                label='Telefone'
                value={this.state.tellphone}
                onChange={(e) => this.handleChange(e, 'tellphone')}
                InputProps={{ inputComponent: Cellphone.default }}
                fullWidth
              />
              <RadioGroup
                row
                aria-label='announce'
                name='announce'
                value={this.state.announce}
                onChange={this.checkChange}
              >
                <FormControlLabel
                  value={2}
                  control={<Radio color='primary' />}
                  label='Quero vender meu imóvel'
                />
                <FormControlLabel
                  value={1}
                  control={<Radio color='primary' />}
                  label='Quero alugar meu imóvel'
                />
              </RadioGroup>
              <TextField
                select
                variant='outlined'
                value={this.state.type}
                input={
                  <OutlinedInput name={this.props.id} id={this.props.id} />
                }
                label='Tipo do Imóvel'
                onChange={(e) => this.setState({ type: e.target.value })}
                fullWidth
              >
                {this.state.types.map((type) => (
                  <MenuItem key={type.nome} value={type.nome}>
                    {type.nome}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                variant='outlined'
                id='street'
                label='Endereço do Imóvel'
                value={this.state.street}
                onChange={(e) => this.handleChange(e, 'street')}
                fullWidth
              />
              <TextField
                variant='outlined'
                id='message'
                label='Alguma observação?'
                value={this.state.message}
                onChange={(e) => this.handleChange(e, 'message')}
                fullWidth
                multiline
                rows={4}
              />
              <TextField
                variant='outlined'
                id='count'
                label={this.state.count + '?'}
                value={this.state.countResult}
                onChange={(e) => this.handleChange(e, 'countResult')}
                fullWidth
              />
              <Button
                fullWidth
                onClick={this.send}
                color='primary'
                variant='contained'
              >
                Enviar
              </Button>
            </Paper>
          </div>
        </section>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(this.state.open)}
          onClose={() => this.setState({ open: '' })}
          message={this.state.open}
        />
      </section>
    )
  }
}

export default Capture
