export const parseMoney = (value = 0) => {
  return value.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  })
}

export const getNumberFromValue = (string) => {
  const withoutRS = string.replace('R$ ', '')
  return parseFloat(withoutRS.replace('.', '').replace(/,/g, '.'))
}

export const getLabel = (options, value) => {
  if (!value) return ''
  const labels = []

  options?.forEach((item) => {
    if (value?.includes(item?.value)) labels.push(item?.option)
  })
  return labels.join(', ')
}