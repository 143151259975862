import React, { useState } from 'react'

import { Button, TextField, Slider } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import './styles.css'
import { parseMoney } from 'helpers/utils'

const marks = [
  {
    value: 5,
    label: '5 anos',
  },
  {
    value: 30,
    label: '30 anos',
  },
]

export const Step2 = ({ handleSubmit, handleBackStep, property }) => {
  const initialFormState = {
    totalGrossIncome: 0,
    propertyValue: +property?.valor?.replace(/\D/g, '') || '',
    FGTSAndEntry: 0,
    birthDate: new Date(),
    deadline: 30,
  }

  const [form, setForm] = useState(initialFormState)

  const handleChange = (name, value) => {
    setForm((form) => ({
      ...form,
      [name]: ['birthDate', 'deadline'].includes(name)
        ? value
        : value?.replace(/\D/g, ''),
    }))
  }

  return (
    <div className='step2'>
      <h1>Agora nos conte sobre a sua renda e o imóvel desejado:</h1>
      <p>
        Vamos entender se a sua renda é compatível ao imóvel dos seus sonhos.
      </p>
      <div className='step2-input-container'>
        <div className='step2-input-content'>
          <label>Soma total da renda bruta mensal</label>
          <TextField
            className='step2-input'
            variant='outlined'
            name='totalGrossIncome'
            value={parseMoney(form.totalGrossIncome / 100)}
            onChange={(e) => handleChange('totalGrossIncome', e.target?.value)}
          />
        </div>
        <div className='step2-input-content'>
          <label>Valor do imóvel desejado</label>
          <TextField
            className='step2-input'
            variant='outlined'
            name='propertyValue'
            value={parseMoney(form.propertyValue / 100)}
            onChange={(e) => handleChange('propertyValue', e.target?.value)}
            fullWidth
          />
        </div>
        <div className='step2-input-content'>
          <label>Entrada + FGTS</label>
          <TextField
            className='step2-input'
            variant='outlined'
            name='FGTSAndEntry'
            value={parseMoney(form.FGTSAndEntry / 100)}
            onChange={(e) => handleChange('FGTSAndEntry', e.target?.value)}
            fullWidth
          />
        </div>
        <div className='step2-input-content'>
          <label>Data de nascimento da pessoa mais velha</label>
          <DatePicker
            format='dd/MM/yyyy'
            value={form.birthDate}
            onChange={(date) => {
              handleChange('birthDate', date)
            }}
            variant='dialog'
            inputVariant='outlined'
            fullWidth
            TextFieldComponent={(props) => (
              <TextField {...props} className='step2-input' name='birthDate' />
            )}
          />
        </div>
        <div className='step2-input-content'>
          <label>Prazo para pagar</label>
          <span className='step2-deadline-value'>
            {form.deadline} anos ({+form?.deadline * 12} meses)
          </span>
          <Slider
            min={5}
            max={30}
            value={form.deadline}
            aria-label='Default'
            valueLabelDisplay='auto'
            marks={marks}
            onChange={(e, value) => {
              handleChange('deadline', value)
            }}
          />
        </div>
      </div>
      <div className='step2-button-container'>
        <Button onClick={handleBackStep} className='step2-button secondary'>
          Voltar
        </Button>
        <Button onClick={() => handleSubmit(form)} className='step2-button'>
          Próximo
        </Button>
      </div>
    </div>
  )
}
