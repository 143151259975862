import React, { useCallback, useEffect, useState } from "react";
import request from "request";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles.css";

import { ScheduleModal } from "./components/PriceMenu/ScheduleModal/index";

import useHooks from "./hooks";
import { useHistory } from "react-router-dom";

import { Header, Footer } from "Components";
import {
  Routes,
  PriceMenu,
  InfoTypes,
  Features,
  GuaranteeModalitiesModal,
  SimilarProperties,
} from "./components";

import { GiSofa } from "react-icons/gi";
import { RiHotelBedLine } from "react-icons/ri";
import { BiRuler } from "react-icons/bi";
import { MdOutlineBathtub } from "react-icons/md";
import { BiCar } from "react-icons/bi";
import { FaHeart, FaRegBuilding, FaShare } from "react-icons/fa";
import sidney from "./../../assets/sidney.png";
import { getNumberFromValue, parseMoney } from "helpers/utils";
import ImageViewer from "react-simple-image-viewer";
import { Button } from "@material-ui/core";
import moment from "moment";
import { FiHeart, FiShare } from "react-icons/fi";
import pet from "../../assets/pata.svg";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StaticExample from "./components/globalModalWpp";
import { Col, Container, Row } from "react-bootstrap";
import Gallery from "./components/galery";
import ItensChoose from "./components/itensChoose";
import Seo from "helpers/tools/Seo";

const Carousel = require("react-responsive-carousel").Carousel;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const notify = () =>
  toast("Obrigado pelo contato. Responderemos em breve!", {
    position: "top-center",
    autoClose: 5000,
    type: "success",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

const campoObrigato = () =>
  toast("Ops! Houve um erro interno. Tente novamente", {
    position: "top-center",
    autoClose: 5000,
    type: "error",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

const Details = () => {
  const isMobileScreen = () => {
    return window.innerWidth <= 600;
  };

  const [camposObrigatoriosComErro, setCamposObrigatoriosComErro] = useState(
    []
  );

  const [aberto, setAberto] = useState(false);
  const [index, setIndex] = useState("foto");

  const validationFormContact = yup.object().shape({
    nome: yup
      .string()
      .required("O nome é obrigatorio")
      .max(50, "Máximo de caracteres permitidos é 50"),
    email: yup
      .string()
      .required("O e-mail é obrigatorio")
      .max(50, "Máximo de caracteres permitidos é 50"),
    celular: yup
      .string()
      .required("O numero de celular ou telefone é obrigatorio")
      .max(11, "O máximo de caracteres é 11"),
    duvida: yup.string().max(500, "Máximo de caracteres permitidos é 500"),
    whatsapp: yup.string(),
  });

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    reset();
    setModalOpen(false);
  };

  const onSubmit = (data, e) => {
    e.preventDefault();

    e.target.reset();

    handleClick();
  };

  function handleClick(data) {
    send(data);
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationFormContact),
  });

  const handleSubmitAsync = async (e) => {
    e.preventDefault();
    setCamposObrigatoriosComErro([]);

    const data = e.target.elements;

    try {
      const camposObrigatorios = ["nome", "email", "celular"];
      const camposComErro = camposObrigatorios.filter(
        (campo) => !data[campo].value.trim()
      );

      if (camposComErro.length > 0) {
        setCamposObrigatoriosComErro(camposComErro);
        campoObrigato();
        return;
      }

      await send(data);
      reset();
      notify();
      setModalOpen(false);
    } catch (error) {
      campoObrigato();
      console.error(error);
    }
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const [isMobile, setIsMobile] = useState(isMobileScreen());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileScreen());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { option, setOption, addFavorite, favoritesList, handleShare } =
    useHooks();
  const { location } = useHistory();

  const data = () => {
    if (location.state) return location.state;

    const localStorageData = JSON.parse(localStorage.getItem("state"));
    if (
      localStorageData &&
      `${localStorageData?.codigo}` === `${location.pathname.split("/").pop()}`
    ) {
      return localStorageData;
    }
    return undefined;
  };
  const [property, setProperty] = useState(data());
  const [expanded, setExpanded] = useState(false);

  const [description, setDescription] = useState("");

  const toggleExpansion = () => {
    setExpanded(!expanded);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    const textarea = document.getElementById("description-textarea");
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [description, expanded]);

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const photos = property?.fotos?.map((item) => item?.url);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const getProperties = async () => {
    const pathname = location.pathname;
    const codigoImovel = pathname.split("/").pop().split("-").pop();
    const properties = await request(
      "GET",
      "Imovel/RetornarDetalhesImovelDisponivel",
      {
        codigoImovel: codigoImovel,
      }
    );
    setProperty(properties?.imovel);
  };

  const send = useCallback(async (data) => {
    const pathname = location.pathname;
    const partesUrl = pathname.split("/").pop().split("-");
    const codigoImovel = partesUrl.pop();
    const finalidadeImovel = partesUrl[0];

    const hours = await request("POST", "/Lead/IncluirLead", {
      nome: data.nome.value,
      email: data.email.value,
      telefone: data.celular.value,
      midia: "SITE",
      codigo: codigoImovel,
      finalidade: property?.finalidade || finalidadeImovel,
      anotacoes: `${
        data.duvida.value
          ? data.duvida.value
          : "Olá, gostaria de ter mais informações deste imóvel. Aguardo seu contato, obrigado."
      } - CONTATO POR: ${data.whatsapp.value}`,
    });
  }, []);

  if (!property) {
    getProperties();
    return null;
  }

  const openImageViewer = (index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const replaceAddress = (endereco) => {
    endereco = endereco.replace(/ /g, "%20");
    endereco = endereco.replace(/-/g, "%20");
    endereco = endereco.replace(/,/g, "%20");
    return endereco;
  };

  const specifications = property.permiteanimais
    ? [
        {
          icon: <GiSofa size={25} color="#570605" />,
          title: property.mobiliado ? "Com mobília" : "Sem mobília",
        },
        {
          icon: <RiHotelBedLine size={25} color="#570605" />,
          title: `${property.numeroquartos} quarto(s)`,
        },
        {
          icon: <BiRuler size={25} color="#570605" />,
          title: `${property.areaprincipal} ${property.tipomedida}`,
        },
        {
          icon: <MdOutlineBathtub size={25} color="#570605" />,
          title: `${property.numerobanhos} banheiro(s)`,
        },
        {
          icon: <BiCar size={25} color="#570605" />,
          title: `${property.numerovagas} vaga(s)`,
        },
        {
          icon: <FaRegBuilding size={25} color="#570605" />,
          title:
            property.numeroandar === 0
              ? "Térreo"
              : `${property.numeroandar}º andar`,
        },
        {
          icon: (
            <img
              src={pet}
              alt="animais"
              style={{
                width: 25,
                filter:
                  "invert(13%) sepia(30%) saturate(3293%) hue-rotate(337deg) brightness(104%) contrast(113%)",
              }}
            />
          ),
          title: "Permite Pets",
        },
      ]
    : [
        {
          icon: <GiSofa size={25} color="#570605" />,
          title: property.mobiliado ? "Com mobília" : "Sem mobília",
        },
        {
          icon: <RiHotelBedLine size={25} color="#570605" />,
          title: `${property.numeroquartos} quarto(s)`,
        },
        {
          icon: <BiRuler size={25} color="#570605" />,
          title: `${property.areaprincipal} ${property.tipomedida}`,
        },
        {
          icon: <MdOutlineBathtub size={25} color="#570605" />,
          title: `${property.numerobanhos} banheiro(s)`,
        },
        {
          icon: <BiCar size={25} color="#570605" />,
          title: `${property.numerovagas} vaga(s)`,
        },
        {
          icon: <FaRegBuilding size={25} color="#570605" />,
          title:
            property.numeroandar === 0
              ? "Térreo"
              : `${property.numeroandar}º andar`,
        },
      ];

  const goToWpp = () => {
    const message =
      "Olá! gostaria de saber mais sobre esse imóvel: " + window.location.href;
    window.open(
      `https://api.whatsapp.com/send?phone=5531989531549&text=${message}`,
      "_blank"
    );
  };

  const extractAndConvertToInteger = (value) => {
    let numericValue = parseFloat(
      value.replace("R$", "").replace(/\./g, "").replace(",", ".")
    );
    return isNaN(numericValue) ? 0 : numericValue;
  };
  const valor = extractAndConvertToInteger(property.valor);
  const valoranterior = extractAndConvertToInteger(property.valoranterior);

  const dataCadastro = moment(property.datahoracadastro, "DD/MM/YYYY HH:mm:ss");
  const dataLimite = moment().subtract(30, "days");

  const getTotalValue = () => {
    const valorcondominio = getNumberFromValue(property.valorcondominio);
    const valor = getNumberFromValue(property.valor);
    const valorIncendio = getNumberFromValue(
      property.valorparcelaseguroincendio
    );
    const valoriptu = getNumberFromValue(property.valoriptu) / 12;

    return `${parseMoney(valorcondominio + valor + valoriptu + valorIncendio)}`;
  };

  const Modal = ({ isOpen, children }) => {
    useEffect(() => {
      const handleOutsideClick = (e) => {
        if (e.target.classList.contains("modal-overlay")) {
          closeModal();
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);

      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, [closeModal]);

    if (!isOpen) {
      return null;
    }

    return (
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-content">{children}</div>
        </div>
      </div>
    );
  };

  const handleOpenMo = () => {
    setAberto(true);
  };

  const handleItemClick = (currentIndex) => {
    setIndex(currentIndex);
  };

  const truncatedLength = 100;
  const truncatedDescription =
    property.descricao.length > 150
      ? `${property.descricao.slice(0, 150)}...`
      : property.descricao;
  return (
    <>
      <Seo
        title={`Ideal - ${property.titulo}`}
        description={truncatedDescription}
        titleOg={`Ideal - ${property.titulo}`}
        imagemSeo={property.fotos[0].url}
      />
      {aberto && <StaticExample openModal={aberto}></StaticExample>}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
      <Header />
      <Modal isOpen={isModalOpen}>
        <h2 style={{ fontSize: "30px" }}>Como podemos te ajudar?</h2>
        <h5>Saiba mais sobre este imóvel</h5>
        <p>Código: {property?.codigo}</p>
        <div>
          <form onSubmit={(e) => handleSubmitAsync(e)} className="formularios2">
            <div className="divForm">
              <p
                style={{
                  textAlign: "start",
                  margin: 0,
                  marginBottom: 10,
                  marginLeft: 12,
                }}
              >
                Nome
              </p>
              <input
                required
                type="text"
                name="nome"
                {...register("nome")}
                className={`inputCustom ${errors.nome ? "inputError" : ""}`}
                placeholder="Seu nome"
              />
              <p className="error-message">{errors.nome?.message}</p>
            </div>
            <div className="form-group divForm">
              <p
                style={{
                  textAlign: "start",
                  margin: 0,
                  marginBottom: 10,
                  marginLeft: 12,
                }}
              >
                Email
              </p>
              <input
                required
                type="email"
                name="email"
                {...register("email")}
                className="inputCustom"
                placeholder="Endereço de e-mail"
              />
              <p className="error-message">{errors.email?.message}</p>
            </div>

            <div className=" divForm">
              <p
                style={{
                  textAlign: "start",
                  margin: 0,
                  marginBottom: 10,
                  marginLeft: 12,
                }}
              >
                Telefone/Celular
              </p>
              <input
                required
                type="text"
                name="celular"
                {...register("celular")}
                className="inputCustom"
                placeholder="Telefone/Celular"
              />
              <p className="error-message">{errors.celular?.message}</p>
            </div>
            <div className="form-group divForm">
              <p
                style={{
                  textAlign: "start",
                  margin: 0,
                  marginBottom: 10,
                  marginLeft: 12,
                }}
              >
                Dúvida
              </p>
              <input
                type="text"
                name="duvida"
                id="duvida"
                {...register("duvida")}
                className="inputCustom"
                placeholder="Escreva sua mensagem"
              />
              <p className="error-message">{errors.mesg?.message}</p>
            </div>
            <div>
              <p>Como você prefere conversar?</p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ marginRight: 20 }}>
                  <input
                    type="radio"
                    id="huey"
                    name="drone"
                    value="WhatsApp"
                    checked
                    {...register("whatsapp")}
                  />
                  <label for="huey">WhatsApp</label>
                </div>

                <div>
                  <input
                    type="radio"
                    id="dewey"
                    name="drone"
                    value="Ligação"
                    {...register("whatsapp")}
                  />
                  <label for="dewey">Ligação Telefônica</label>
                </div>
              </div>
            </div>
            {camposObrigatoriosComErro.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  margin: 0,
                }}
              >
                <p style={{ color: "red" }}>
                  Preencha todos os campos obrigatório
                </p>
              </div>
            ) : null}

            <div style={{ display: "flex" }}>
              <button className="botao" style={{ marginRight: 20 }}>
                Entre em Contato
              </button>
              <button className="botaoFechar" onClick={closeModal}>
                Fechar
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <div className="details-container">
        <div className="details-content">
          {index === "foto" && <Gallery data={property.fotos} />}
          {index === "video" && (
            <div className="frameVideo">
              <iframe
                width={window.screen.width < 400 ? "560" : "1890"}
                height="360"
                src={property.urlvideo}
                frameBorder="0"
                title="videolocal"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </div>
          )}
          {index === "map" && (
            <iframe
              width={!isMobile ? "1890" : "100%"}
              height="360"
              id="gmap_canvas"
              src={`https://maps.google.com/maps?q=${replaceAddress(
                `${
                  property.finalidade === "Alugar"
                    ? `${property.endereco}`
                    : `${property.bairro}`
                }, ${property.cidade}-${property.estado}`
              )}&t=&z=15&radius=5&ie=UTF8&iwloc=&output=embed`}
              frameBorder="0"
              scrolling="no"
              title="geolocalizacao"
              marginHeight="0"
              marginWidth="0"
            ></iframe>
          )}

          <div className="details-info-container">
            <section className="details-section1">
              <div>
                <div className="container">
                  <div className="row">
                    <div
                      className="col-sm-12 col-md-12"
                      style={{ width: "100%" }}
                    >
                      <ItensChoose
                        property={property}
                        onClick={handleItemClick}
                      />
                    </div>
                  </div>
                </div>
                <div className="container" style={{ marginTop: 20 }}>
                  <div className="row">
                    <div className="titles01">
                      <h2 className="tituloItemss">
                        {property.titulo} - {parseInt(property.areainterna)}
                        {property.tipomedida}
                      </h2>
                      <div>
                        <p className="tituloValores" style={{}}>
                          {property.finalidade === "Aluguel"
                            ? `Total: ${getTotalValue()}`
                            : `Valor do imóvel: ${property.valor}`}
                        </p>
                        <p
                          style={{
                            margin: 0,
                            textAlign: "start",
                            fontSize: 18,
                            marginBottom: 25,
                          }}
                        >
                          {property.finalidade === "Aluguel"
                            ? `Aluguel: ${property.valor}`
                            : null}
                        </p>
                        <div style={{ display: "flex", fontWeight: "bold" }}>
                          {dataCadastro.isValid() &&
                            dataCadastro.isAfter(dataLimite) && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                }}
                              >
                                <svg
                                  style={{ marginRight: 10 }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="orange"
                                  class="bi bi-bookmark-heart-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M2 15.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v13.5zM8 4.41c1.387-1.425 4.854 1.07 0 4.277C3.146 5.48 6.613 2.986 8 4.412z" />
                                </svg>

                                <p style={{ margin: 0, marginRight: 10 }}>
                                  Anúncio novo
                                </p>
                              </div>
                            )}
                          {property.exclusivo && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                              }}
                            >
                              <svg
                                style={{ marginRight: 10 }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="orange"
                                class="bi bi-fire"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z" />
                              </svg>

                              <p style={{ margin: 0, marginRight: 10 }}>
                                Exclusivo
                              </p>
                            </div>
                          )}
                          {property.naplanta && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                              }}
                            >
                              <svg
                                style={{ marginRight: 10 }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="orange"
                                class="bi bi-card-checklist"
                                viewBox="0 0 16 16"
                              >
                                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                              </svg>

                              <p style={{ margin: 0, marginRight: 10 }}>
                                Na planta
                              </p>
                            </div>
                          )}
                          {valor < valoranterior && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                              }}
                            >
                              <svg
                                style={{ marginRight: 10 }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="orange"
                                class="bi bi-currency-exchange"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z" />
                              </svg>
                              <p style={{ margin: 0, marginRight: 10 }}>
                                Baixou o preço
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#7e1d1f"
                          class="bi bi-geo-alt-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        <p
                          style={{
                            textAlign: "start",
                            marginLeft: 2,
                          }}
                        >{`${property.endereco}, ${property.bairro} - ${property.cidade}-${property.estado} - CÓD. ${property.codigo}`}</p>
                      </div>
                      <div className="titles02">
                        <div className="btnContainers">
                          <button className="btnNovos" onClick={handleShare}>
                            <FiShare
                              size={22}
                              color="#0000"
                              style={{ marginRight: 10 }}
                            />
                            Compartilhar
                          </button>

                          <button
                            className="btnNovos"
                            onClick={() => {
                              addFavorite(property);
                            }}
                          >
                            {Array.from(favoritesList).includes(
                              `${property?.codigo}`
                            ) ? (
                              <FaHeart
                                size={22}
                                color="#0000"
                                style={{ marginRight: 10 }}
                              />
                            ) : (
                              <FiHeart
                                size={22}
                                color="#570605"
                                style={{ marginRight: 10 }}
                              />
                            )}
                            Favoritar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr
                  style={{
                    border: "1px solid rgb(239, 236, 243)",
                    width: "98%",
                    display: "flex",
                    marginLeft: 0,
                  }}
                />
              </div>

              <div className="details-info-description-content">
                <div className="details-screen-price-menu-small-screens">
                  <PriceMenu
                    addFavorite={addFavorite}
                    property={property}
                    favoritesList={favoritesList}
                    handleShare={handleShare}
                  />
                </div>

                <div className="row">
                  <h2>Especificações</h2>
                  <div className="details-info-specifications">
                    {specifications.map((item) => (
                      <div key={item.title}>
                        <div className="icon">{item.icon}</div>
                        <span>{item.title}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="row">
                  <h2>Descrição do imóvel</h2>
                  <textarea
                    id="description-textarea"
                    style={{ color: "black", height: "60px", border: "none" }}
                    readOnly={true}
                    value={expanded ? property.descricao : truncatedDescription}
                    onChange={handleDescriptionChange}
                  ></textarea>
                  {property.descricao.length > truncatedLength && (
                    <>
                      <br />
                      <button className="btn-config" onClick={toggleExpansion}>
                        {expanded ? "Ver menos" : "Ler mais"}
                      </button>
                    </>
                  )}
                </div>

                <div className="row">
                  <h2>Características Internas</h2>
                  <Features property={property} type="internal" />
                </div>

                <hr
                  style={{
                    border: "1px solid rgb(239, 236, 243)",
                    width: "98%",
                    display: "flex",
                    marginLeft: 0,
                  }}
                />

                <div className="row">
                  <h2>Características Externas</h2>
                  <Features property={property} type="external" />
                </div>

                {property.finalidade === "Aluguel" ? (
                  <div className="row">
                    <h2>O que preciso para alugar esse imóvel?</h2>
                    <GuaranteeModalitiesModal />
                  </div>
                ) : (
                  <div className="row">
                    <h2>O que o especialista de vendas da Ideal oferece?</h2>
                    <ul>
                      <li>
                        <p>
                          Te ajuda no financiamento, cuidando de tudo para você.
                        </p>
                      </li>
                      <li>
                        <p>Processo transparente e seguro</p>
                      </li>
                      <li>
                        <p>Não te abandona depois do contrato assinado</p>
                      </li>
                      <li>
                        <p>
                          Tira todas as suas dúvidas do processo, do início ao
                          fim
                        </p>
                      </li>
                      <li>
                        <p>Encontra o imóvel do seu sonho</p>
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              <div className="maisInfo">
                <div className="containerInfoFoto">
                  <div className="fotao">
                    <div className="blockFoto">
                      <img className="sidneyInfo" src={sidney} alt="siney" />
                    </div>
                  </div>

                  <div className="containerInfoDetalhes">
                    <h5>Quer mais informações?</h5>
                    <p>Fale com um de nossos especialistas.</p>
                  </div>

                  <div style={{ marginTop: 10 }}>
                    <p onClick={openModal} className="buttonInfosMsg">
                      Enviar Mensagem
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="details-screen-section-2">
              <PriceMenu
                addFavorite={addFavorite}
                property={property}
                favoritesList={favoritesList}
                handleShare={handleShare}
                data={property}
              />
            </section>
          </div>

          <div className="row itenCardSimilar">
            <SimilarProperties currentProperty={property} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Details;
