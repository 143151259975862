import axios from 'axios'

const http = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    chave: 'blL2qgpsB/kA7ECQgunzbM0wOetqYkH2nfKfNDRcDO0=',
  },
})

const methods = {
  GET: async (url, data) => await http.post(url, { ...data, method: 'get' }),
  POST: async (url, data) => await http.post(url, data),
  PUT: async (url, data) => await http.put(url, data),
  DELETE: async (url, params) => await http.delete(url, { ...params }),
}

const request = async (method, endpoint, data = {}) => {
  try {
    const res = await methods[method](endpoint, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export default request
