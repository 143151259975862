import React from "react";

import "./styles.css";

import { Header, Footer } from "Components";
import { Section1, Section2, Section3 } from "./components";
import Seo from "helpers/tools/Seo";

const Indicates = () => {
  return (
    <>
      <Seo
        title="Ideal - Indica aí"
        description="Ganhe dinheiro indicando imóveis para
alugar ou vender."
        titleOg="Ideal - Indicando Imóveis"
      />
      <Header />
      <div className="indicate-container">
        <Section1 />
        <Section2 />
        <Section3 />
      </div>
      <Footer />
    </>
  );
};

export default Indicates;
