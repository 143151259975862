import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import './styles.css'

export const Loading = () => (
  <div className='loading'>
    <CircularProgress />
    <h1>Carregando...</h1>
  </div>
)
