import React from "react";

import "./style.css";
import { IconButton } from "@material-ui/core";

import {
  RiWhatsappFill,
  RiFacebookCircleFill,
  RiPhoneFill,
} from "react-icons/ri";
import { FiInstagram } from "react-icons/fi";

const callPhone = () => {
  try {
    window.location.href = "tel:+553138911524";
  } catch {
    navigator.clipboard.writeText("553138911524").then(() => {
      alert("Telefone copiado!");
    });
  }
};

export const Footer = () => {
  const contacts = [
    {
      name: "Telefone",
      action: () => callPhone(),
      icon: <RiPhoneFill color="#fff" size={50} />,
    },
    {
      name: "Facebook",
      action: () =>
        (window.location.href = "https://www.facebook.com/naidealtem/"),
      icon: <RiFacebookCircleFill color="#fff" size={52} />,
    },
    {
      name: "Instagram",
      action: () =>
        (window.location.href =
          "https://instagram.com/ideal_negocios_imobiliarios?igshid=1pm4u1n6wwgs4"),
      icon: <FiInstagram color="#fff" size={50} />,
    },
    {
      name: "Whatsapp",
      action: () => (window.location.href = "https://wa.me/553189531549"),
      icon: <RiWhatsappFill color="#fff" size={50} />,
    },
  ];

  return (
    <footer className="footer">
      <div className="container-fluid text-center fluido">
        <div class="row">
          <div class="col">
            <div className="containerInfoFooter">
              <p className="endereco">Endereço</p>
              <p className="endercorua">
                Avenida Peter Henry Rolfs, n° 156, Loja 01, Centro, Viçosa - MG
                - CEP 36.570-087
              </p>
            </div>

            <div className="containerInfoFooter">
              <p className="endereco">Funcionamento</p>
              <p className="endercorua">
                Horário de funcionamento: Segunda à Sexta de 8h às 18h e Sábado
                de 8h às 12h
              </p>
            </div>

            <div className="containerInfoFooter">
              <p className="endereco">Contato</p>
              <p className="endercorua">(31) 3891-1524</p>
              <p className="endercorua">atendimento@idealni.com.br</p>
            </div>
          </div>

          <div class="col">
            <div className="iconsSocial">
              {contacts.map((item) => (
                <div key={item.name} className="footer-icons-content">
                  <IconButton key={item.name} onClick={item.action}>
                    {item.icon}
                  </IconButton>
                </div>
              ))}
            </div>

            <div className="credits">
              <p className="direitos">
                Todos os direitos reservados ®SSS Empreendimentos
                administrativos LTDA
              </p>
            </div>
            <p className="creci"> Creci - MG PJ 3932</p>
          </div>
        </div>
      </div>
    </footer>
  );
};
