import React from 'react'
import emptyImage from 'assets/empty.png'
import './styles.css'

export const EmptyPage = () => (
  <div className='empty'>
    <h1>Que tal usar um filtro diferente?</h1>
    <img src={emptyImage} alt='vazio' />
    <h2>Nenhum imóvel encontrado</h2>
  </div>
)
