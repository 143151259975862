import React, { useEffect, useState } from "react";
import { SimilarPropertyCard } from "./SimilarPropertyCard";
import { useHooks } from "./hooks";
import "./styles.css";
import { Carousel } from "react-responsive-carousel";

export const SimilarProperties = ({ currentProperty }) => {
  const isMobileScreen = () => {
    return window.innerWidth <= 600;
  };

  const [isMobile, setIsMobile] = useState(isMobileScreen());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileScreen());
    };

    window.addEventListener("resize", handleResize);

    var elemento = document.querySelector(".slide");

    if (elemento) {
      elemento.removeAttribute("style");
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { data, openProperty, favoritesList, addFavorite, removeFavorite } =
    useHooks(currentProperty);
  if (!data || data?.length === 0) return null;

  const getSliderPercentage = () => {
    const width = window.screen.width;
    if (width < 650) return 100;
    if (width < 700) return 80;
    if (width < 800) return 30;
    if (width < 900) return 30;
    if (width < 1000) return 30;
    return 30;
  };

  return (
    <div
      className={`similar-properties-container ${
        isMobile ? "mobile" : "desktop"
      }`}
    >
      <div className="widthSimilar">
        <h2 style={{ textAlign: "start" }} className="descricaoImovel">
          Imóveis similares
        </h2>

        {data && data.length > 0 && (
          <Carousel
            interval={2500}
            autoPlay={true}
            showArrows={true}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={data.length > 3}
            centerMode={data.length > 1}
            centerSlidePercentage={getSliderPercentage()}
            selectedItem={data.length > 3 ? 1 : 0}
            className="similar-properties-carousel"
          >
            {data.map((item) => (
              <SimilarPropertyCard
                key={item.codigo}
                openProperty={openProperty}
                property={item}
                addFavorite={addFavorite}
                removeFavorite={removeFavorite}
                favoritesList={favoritesList}
                style={{ marginLeft: 900 }}
              />
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};
