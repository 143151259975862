import React, { useEffect, useState } from "react";

import {
  Header,
  RentCard,
  Pagination,
  Footer,
  EmptyPage,
  Loading,
} from "Components";
import Sidebar from "./components/Sidebar";
import FilterModal from "./components/FilterModal";
import ScrollAnimation from "react-animate-on-scroll";

import { useHooks } from "./hooks";
import "./Rent.css";
import Seo from "helpers/tools/Seo";

const Rent = () => {
  const [open, setOpen] = useState(false);

  const {
    form,
    type,
    setType,
    handleChange,
    neighborhoods,
    cities,
    types,
    page,
    setPage,
    totalPages,
    loading,
    data,
    openProperty,
    favoritesList,
    setFavoritesList,
    canShowBadroomAndBathRoomFilters,
  } = useHooks();

  return (
    <>
      <Seo
        title={
          type === "alugar"
            ? "Ideal - Alugar Imóveis"
            : "Ideal - Comprar Imóveis"
        }
        description={
          type === "alugar"
            ? "Imóveis para alugar: Acompanhe a nossa lista de imóveis para alugar com filtros personalizados!"
            : "Imóveis para comprar: Acompanhe a nossa lista de imóveis para comprar com filtros personalizados!"
        }
        titleOg={
          type === "alugar"
            ? "Ideal - Imovéis disponíveis para alugar"
            : "Ideal - Imovéis disponíveis para comprar"
        }
      />

      <Header />

      <div className="rent-page">
        <div className="rent-container">
          <FilterModal
            open={open}
            setOpen={setOpen}
            type={type}
            setType={setType}
            form={form}
            handleChange={handleChange}
            neighborhoods={neighborhoods}
            cities={cities}
            types={types}
            canShowBadroomAndBathRoomFilters={canShowBadroomAndBathRoomFilters}
          />
          <ScrollAnimation animateIn="bounceInLeft" animateOnce={true}>
            <Sidebar
              type={type}
              setType={setType}
              form={form}
              handleChange={handleChange}
              neighborhoods={neighborhoods}
              cities={cities}
              types={types}
              canShowBadroomAndBathRoomFilters={
                canShowBadroomAndBathRoomFilters
              }
            />
          </ScrollAnimation>

          <ScrollAnimation
            animateIn="fadeIn"
            style={{ width: "100%", height: "100%" }}
            animateOnce={true}
          >
            <div className="list-rent-container">
              {loading && (
                <div className="loading-container">
                  <Loading />
                </div>
              )}
              {(!data || data.length === 0) && !loading && <EmptyPage />}
              {data && data.length !== 0 && !loading && (
                <div className="item-container">
                  {data.map((item) => (
                    <div className="item" key={item.codigo}>
                      <RentCard
                        openProperty={openProperty}
                        data={item}
                        favoritesList={favoritesList}
                        setFavoritesList={setFavoritesList}
                      />
                    </div>
                  ))}
                </div>
              )}
              {data && data.length !== 0 && (
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              )}
            </div>
          </ScrollAnimation>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Rent;
