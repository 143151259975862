import React from 'react'

import './styles.css'
import { useHooks } from './hooks'
import logo from 'assets/logo.png'

import { FiX } from 'react-icons/fi'
import { Modal, Button, IconButton, TextField } from '@material-ui/core'
import ReactInputMask from 'react-input-mask'

export const IndicateModal = () => {
  const {
    open,
    handleClose,
    handleOpen,
    handleSubmit,
    handleChange,
    form,
    hasError,
  } = useHooks()

  return (
    <>
      <Button className='indicateButton' onClick={handleOpen}>
        Indicar agora
      </Button>
      <Modal open={open} onClose={handleClose}>
        <div className='indicate-modal'>
          <header className='indicate-modal-header'>
            <span>Indique um Imóvel</span>
            <IconButton onClick={handleClose}>
              <FiX size={22} color='#fff' />
            </IconButton>
          </header>
          <div className='indicate-modal-content'>
            <img src={logo} alt='Logo' />
            <h3>Preencha os dados abaixo que entraremos em contato.</h3>
            <form onSubmit={handleSubmit}>
              <TextField
                variant='outlined'
                autoFocus
                name='name'
                className='input'
                label='Seu nome'
                value={form.values.name}
                onChange={handleChange}
                fullWidth
                error={hasError('name')}
                helperText={hasError('name') && form.errors['name'][0]}
              />
              <ReactInputMask
                mask={'(99) 99999-9999'}
                value={form.values.phone}
                onChange={handleChange}
                maskChar={''}
              >
                {() => (
                  <TextField
                    error={hasError('phone')}
                    helperText={hasError('phone') && form.errors['phone'][0]}
                    name='phone'
                    fullWidth
                    className='input'
                    variant='outlined'
                    label='Seu telefone'
                  />
                )}
              </ReactInputMask>
              <TextField
                variant='outlined'
                name='propertyOwnerName'
                label='Nome do proprietário'
                value={form.values.propertyOwnerName}
                onChange={handleChange}
                className='input'
                fullWidth
                error={hasError('propertyOwnerName')}
                helperText={
                  hasError('propertyOwnerName') &&
                  form.errors['propertyOwnerName'][0]
                }
              />
              <ReactInputMask
                mask={'(99) 99999-9999'}
                value={form.values.propertyOwnerPhone}
                onChange={handleChange}
                maskChar={''}
              >
                {() => (
                  <TextField
                    error={hasError('propertyOwnerPhone')}
                    helperText={
                      hasError('propertyOwnerPhone') &&
                      form.errors['propertyOwnerPhone'][0]
                    }
                    name='propertyOwnerPhone'
                    fullWidth
                    className='input'
                    variant='outlined'
                    label='Telefone do proprietário'
                  />
                )}
              </ReactInputMask>
              <TextField
                variant='outlined'
                name='fullAddress'
                className='input'
                label='Endereço completo'
                value={form.values.fullAddress}
                onChange={handleChange}
                fullWidth
                error={hasError('fullAddress')}
                helperText={
                  hasError('fullAddress') && form.errors['fullAddress'][0]
                }
              />
              <div className='indicateButtonContainer'>
                <Button onClick={handleClose} className='secondary'>
                  Cancelar
                </Button>
                <Button type='submit'>Indicar</Button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  )
}
