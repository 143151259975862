import React from 'react'

import { IconButton } from '@material-ui/core'
import { MdOutlineDirectionsCar } from 'react-icons/md'
import { BiRuler } from 'react-icons/bi'
import { RiHotelBedLine } from 'react-icons/ri'
import { FiHeart } from 'react-icons/fi'
import { FaHeart } from 'react-icons/fa'

export const SimilarPropertyCard = ({
  property,
  openProperty,
  addFavorite,
  removeFavorite,
  favoritesList,
}) => {
  const infoIcons = [
    {
      title: 'Tamanho',
      value: `${property?.areaprincipal} ${property?.tipomedida}`,
      icon: <BiRuler color='#1c1c1c' size={25} />,
    },
    {
      title: 'Vaga(s)',
      value: property?.numerovagas,
      icon: <MdOutlineDirectionsCar color='#1c1c1c' size={25} />,
    },
    {
      title: 'Quarto(s)',
      value: property?.numeroquartos,
      icon: <RiHotelBedLine color='#1c1c1c' size={25} />,
    },
  ]

  return (
    <div className='similar-property-card-container' >
      <img src={property.urlfotoprincipal} alt='Imagem da propriedade' />
      <div
        onClick={() => {
          openProperty(property)
        }}
        className='similar-property-info-container'
      >
        <div className='similar-property-info-title'>
          <div className='similar-property-info-title-content'>
            <span>{property.endereco}</span>
            <h3>{`${property.bairro} - ${property.cidade}/${property.estado}`}</h3>
          </div>
        </div>
      </div>
      <div className='similar-property-values'>
        <h2>{property.valor}</h2>
        <span>cond. {property.valorcondominio}</span>
      </div>
      <div className='similar-property-icons'>
        {infoIcons.map((item) => (
          <div className='similar-property-icon' key={item.title}>
            {item.icon}
            <span className='similar-property-text-icon'>{item.title}</span>
            <span className='similar-property-text-icon'>{item.value}</span>
          </div>
        ))}
      </div>
      <IconButton
        onClick={
          Array.from(favoritesList).includes(`${property?.codigo}`)
            ? () => {
                removeFavorite(property)
              }
            : () => {
                addFavorite(property)
              }
        }
        className='similar-property-favorite-button'
      >
        {Array.from(favoritesList).includes(`${property?.codigo}`) ? (
          <FaHeart color='#570605' size={30} />
        ) : (
          <FiHeart color='#303030' size={30} />
        )}
      </IconButton>
    </div>
  )
}
