import React, { useState } from 'react'

import request from 'request'
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert'
import './Styles.css'

import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core'

const SearchByCode = ({ open, handleClose }) => {
  const { push } = useHistory()
  const [code, setCode] = useState('')

  const search = async () => {
    sessionStorage.clear()

    try {
      const properties = await request(
        'GET',
        'Imovel/RetornarDetalhesImovelDisponivel',
        {
          codigoImovel: code,
        }
      )
      push(`detalhes/imovel/${properties.imovel.finalidade}-${properties.imovel.tipo}-${properties.imovel.numeroquartos}-quartos-${properties.imovel.bairro}-${properties.imovel.cidade}-${properties.imovel.estado}-${properties.imovel.codigo}`  
      , {
        ...properties?.imovel,
      })
      handleClose()

      if (!properties?.imovel) {
        swal({
          title: 'Ops...',
          text: 'Houve um erro ao procurar o imóvel! Tente novamente.',
          icon: 'error',
        })
      }
    } catch (err) {
      swal({
        title: 'Imóvel com o código informado não foi encontrado',
        icon: 'error',
      })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle className='code-modal-title'>
        Insira o código desejado
      </DialogTitle>
      <DialogContent>
        <TextField
          variant='outlined'
          autoFocus
          className='code-modal-input'
          placeholder='Código'
          value={code}
          onChange={(e) => setCode(e?.target?.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          className='code-modal-button'
          onClick={search}
          color='primary'
          variant='contained'
        >
          Buscar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SearchByCode
