import React from "react";

import { Flag } from "Components";
import { IconButton } from "@material-ui/core";

import { MdOutlineBathtub, MdOutlineDirectionsCar } from "react-icons/md";
import { BiRuler } from "react-icons/bi";
import { RiHotelBedLine } from "react-icons/ri";
import { FiHeart } from "react-icons/fi";
import { FaHeart } from "react-icons/fa";
import swal from "sweetalert";
import moment from "moment";

import "./styles.css";

export const RentCard = ({
  data,
  openProperty,
  favoritesList,
  setFavoritesList,
}) => {
  const addFavorite = () => {
    const newFavorites = favoritesList.length > 0 ? [...favoritesList] : [];
    newFavorites.push(`${data?.codigo}`);
    localStorage.setItem("favorites", newFavorites.join(","));
    setFavoritesList(newFavorites);
    swal({
      title: "Imóvel adicionado aos favoritos",
      icon: "success",
      timer: 1500,
    });
  };

  const removeFavorite = () => {
    const newFavorites = favoritesList.length > 0 ? [...favoritesList] : [];
    newFavorites.splice(newFavorites.indexOf(`${data.codigo}`), 1);
    localStorage.setItem("favorites", newFavorites.join(","));
    setFavoritesList(newFavorites);
  };

  const infoIcons = [
    {
      title: "Banheiro(s)",
      value: data.numerobanhos,
      icon: <MdOutlineBathtub color="#1c1c1c" size={25} />,
    },
    {
      title: "Tamanho",
      value: `${data.areaprincipal} ${data.tipomedida}`,
      icon: <BiRuler color="#1c1c1c" size={25} />,
    },
    {
      title: "Vaga(s)",
      value: data.numerovagas,
      icon: <MdOutlineDirectionsCar color="#1c1c1c" size={25} />,
    },
    {
      title: "Quarto(s)",
      value: data.numeroquartos,
      icon: <RiHotelBedLine color="#1c1c1c" size={25} />,
    },
  ];

  //converte a data para moment
  const dataCadastro = moment(data.datahoracadastro, "DD/MM/YYYY HH:mm:ss");
  const dataLimite = moment().subtract(30, "days");

  // Função para extrair números e converter para inteiro
  const extractAndConvertToInteger = (value) => {
    let numericValue = parseFloat(
      value.replace("R$", "").replace(/\./g, "").replace(",", ".")
    );
    return isNaN(numericValue) ? 0 : numericValue;
  };
  const valor = extractAndConvertToInteger(data.valor);
  const valoranterior = extractAndConvertToInteger(data.valoranterior);

  return (
    <div className="rent-card-item">
      <div className="rent-card-item-content">
        <div className="rent-card-card-img-container">
          <div>
            <div className="containerItensPo">
              <div className="flexao">
                {data.exclusivo && ( // verifica se o item é exclusivo
                  <div className="containerAnuncio">
                    <p className="anuncioNovo">Exclusivo</p>
                  </div>
                )}
                {dataCadastro.isValid() &&
                  dataCadastro.isAfter(dataLimite) && ( // Verifica se a data é válida e está dentro do limite de 30 dias
                    <div className="containerAnuncio">
                      <p className="anuncioNovo">Anúncio novo</p>
                    </div>
                  )}
                {data.naplanta && ( // verifica se está na planta
                  <div className="containerAnuncioExclusivo">
                    <p className="anuncioNovo">Na planta</p>
                  </div>
                )}
                {valor < valoranterior && ( // Verifica se o valor atual é menor que o valor anterior
                  <div className="containerAnuncioExclusivo">
                    <p className="anuncioNovo">Baixou o preço</p>
                  </div>
                )}
              </div>
            </div>

            <img
              onClick={() => {
                openProperty(data);
              }}
              className="rent-card-card-img"
              src={data.urlfotoprincipal}
              alt="Imagem da propriedade"
            />
          </div>

          <div className="rent-card-flag">
            <div>
              <p className="finalidade">{data.finalidade}</p>
              <p className="tipo">{data.tipo}</p>
            </div>

            <IconButton
              onClick={
                Array.from(favoritesList).includes(`${data?.codigo}`)
                  ? removeFavorite
                  : addFavorite
              }
              className="rent-card-heart-icon"
            >
              {Array.from(favoritesList).includes(`${data?.codigo}`) ? (
                <FaHeart color="#570605" size={30} />
              ) : (
                <FiHeart color="#303030" size={30} />
              )}
            </IconButton>
          </div>
          <div
            onClick={() => {
              openProperty(data);
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="rent-card-info-address-container">
              <div className="containerLoca">
                <div className="iconLoca">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="red"
                    class="bi bi-geo-alt-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                </div>
                <div>
                  {data?.finalidade === "Aluguel" && (
                    <h2 className="rent-card-info-subtitle">{data.endereco}</h2>
                  )}
                  {data?.finalidade === "Venda" && (
                    <h2 className="rent-card-info-subtitle">{data.endereco}</h2>
                  )}
                  <h1 className="rent-card-info-title">{`${data.bairro}, ${data.cidade}`}</h1>
                </div>
              </div>
            </div>
            <h1 className="rent-card-value">{data.valor}</h1>
            <p className="codigoValue">{` Cód. ${data.codigo}`}</p>
            <div className="rent-card-icons-container">
              {infoIcons.map((item) => (
                <div className="rent-card-icon" key={item.title}>
                  {item.icon}
                  <span className="rent-card-text-icon">{item.title}</span>
                  <span className="rent-card-text-icon">{item.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="rent-card-info-container"></div>
      </div>
    </div>
  );
};
