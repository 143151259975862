import React from "react";
import { Helmet } from "react-helmet";
import tagsSeo from "./../mocks/tags_seo.js";

const Seo = ({ title, description, titleOg }) => {
  const canonicalUrl = `https://idealni.com.br${window.location.pathname}${window.location.search}`;
  return (
    <Helmet >
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={tagsSeo} />
      <link rel="canonical" href={canonicalUrl} />
      {titleOg && <meta property="og:title" content={titleOg} />}
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content="https://idealni.com.br/static/media/familia.97007adef2999eabc794.jpg"
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="400" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

export default Seo;
