import React from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Slider,
  Select,
  MenuItem,
  FormControl,
  Chip,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import { parseMoney, getLabel } from "helpers/utils";
import "./styles.css";

const Sidebar = ({
  form,
  type,
  setType,
  handleChange,
  neighborhoods,
  cities,
  types,
  canShowBadroomAndBathRoomFilters,
}) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const path = location.pathname.split("/").pop();

  const options = [
    { name: "1+", value: 1 },
    { name: "2+", value: 2 },
    { name: "3+", value: 3 },
    { name: "4+", value: 4 },
  ];

  return (
    <aside className="sidebar-container">
      <div className="sidebar-content-container">
        <div className="sidebar-button-container">
          <Button
            onClick={() => {
              setType("alugar");
            }}
            className={`sidebar-rent-button ${
              type === "alugar" || path === "alugar" ? "selected" : ""
            }`}
          >
            Alugar
          </Button>
          <Button
            onClick={() => {
              setType("comprar");
            }}
            className={`sidebar-purchase-button ${
              type === "comprar" || path === "comprar" ? "selected" : ""
            }`}
          >
            Comprar
          </Button>
        </div>
        <div className="sidebar-input">
          <label>Código</label>
          <input
            type="text"
            value={form.code}
            onChange={(e) => handleChange(e.target?.value, "code")}
          />
        </div>
        <div className="sidebar-input">
          <label>Localização</label>
          <FormControl fullWidth>
            <Select
              className="sidebar-select-input"
              value={form.city}
              disableUnderline
              onChange={(e) => handleChange(e.target?.value, "city")}
            >
              {cities.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {form?.city && form.city !== "" && (
          <div className="sidebar-input">
            <label>Bairro</label>
            <FormControl fullWidth>
              <Select
                className="sidebar-select-input"
                value={form.neighborhood}
                disableUnderline
                renderValue={(selected) => getLabel(neighborhoods, selected)}
                multiple
                onChange={(e) => handleChange(e.target?.value, "neighborhood")}
              >
                {neighborhoods.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    <Checkbox
                      color="primary"
                      checked={form.neighborhood?.indexOf(item.value) > -1}
                    />
                    <ListItemText>{item.option}</ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        <div className="sidebar-input">
          <label>Valor do imóvel</label>
          <div className="sidebar-property-value">
            <span>De {`${parseMoney(form.value[0])}`}</span>
            <span>Até {`${parseMoney(form.value[1])}`}</span>
          </div>
          <Slider
            value={form.value}
            defaultValue={form.value}
            onChange={(e, value) => handleChange(value, "value")}
            color="primary"
            step={type === "alugar" ? 200 : 20000}
            max={type === "alugar" ? 20000 : 2000000}
            min={0}
          />
        </div>
        <div className="sidebar-input">
          <label>Tipo de imóvel</label>
          {types.map((type) => (
            <Chip
              className="sidebar-chip"
              key={type.value}
              label={type.option}
              color={
                form.types.indexOf(type.value) > -1 ? "primary" : "secondary"
              }
              onClick={() => handleChange(type.value, "types")}
            />
          ))}
        </div>

        <div className="sidebar-input">
          <label>Banheiros</label>
          <div className="sidebar-chip-row-container">
            {options.map((item) => (
              <Chip
                className="sidebar-chip-circle"
                key={item.value}
                label={item.name}
                color={form.bathrooms === item.value ? "primary" : "secondary"}
                onClick={() => handleChange(item.value, "bathrooms")}
              />
            ))}
          </div>
        </div>
        <div className="sidebar-input">
          <label>Quartos</label>
          <div className="sidebar-chip-row-container">
            {options.map((item) => (
              <Chip
                className="sidebar-chip-circle"
                key={item.value}
                label={item.name}
                style={{
                  color: form.bedrooms === item.value ? "#fff" : "#3F0B0B",
                }}
                color={form.bedrooms === item.value ? "primary" : "secondary"}
                onClick={() => handleChange(item.value, "bedrooms")}
              />
            ))}
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
