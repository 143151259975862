import React, { useState } from 'react'

import { Button, Modal, IconButton } from '@material-ui/core'
import { FiInfo, FiX } from 'react-icons/fi'

export const RegulationModal = () => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button onClick={handleOpen}>
        <FiInfo size={20} style={{ marginRight: 15 }} />
        Ver regulamento
      </Button>
      <Modal open={open} onClose={handleClose}>
        <div className='indicate-regulation-modal'>
          <header className='indicate-regulation-modal-header'>
            <span>Regulamento</span>
            <IconButton onClick={handleClose}>
              <FiX size={22} color='#fff' />
            </IconButton>
          </header>
          <div className='indicate-regulation-modal-content'>
            <fieldset>
              <ul>
                <li>
                  O participante deverá informar na hora do cadastro seu nome
                  completo, CPF, data de nascimento e telefone.
                </li>
                <li>
                  O participante deverá informar para a Ideal Negócios
                  Imobiliários o endereço completo do imóvel a ser indicado,
                  telefone e nome completo do proprietário do imóvel. Essas
                  informações deverão ser válidas, quanto mais informações forem
                  disponibilizadas, maior será a agilidade para a publicação do
                  imóvel no site, consequentemente, do usuário receber os
                  prêmios pela indicação.
                </li>
                <li>
                  O usuário terá direito a premiação somente se o proprietário
                  aderir e se o imóvel indicado for publicado em nosso{' '}
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.idealni.com.br'
                  >
                    Portal
                  </a>
                  .
                </li>
                <li>
                  Já se o imóvel for Residencial, além do valor da indicação o
                  usuário receberá R$400,00 adicional se o imóvel for vendido.
                </li>
                <li>
                  Eventualmente se dois usuários indicarem o mesmo imóvel, a
                  primeira indicação que for aprovada e publicada no site será a
                  indicação remunerada.
                </li>
                <li>
                  A Ideal Negócios Imobiliários não efetuará pagamentos em
                  duplicidade.
                </li>
                <li>
                  Somente serão aceitas indicações de imóveis na região de
                  atuação da Ideal Negócios Imobiliários: Viçosa.
                </li>
                <li>
                  Para participação no INDICA VAI da Ideal Negócios
                  Imobiliários, o usuário deverá ser pessoa física, ter mais de
                  18 (dezoito) anos de idade e ser capaz de celebrar contratos.
                </li>
                <li>
                  Imóveis já cadastrados em nosso banco de dados não serão
                  aceitos.
                </li>
                <li>
                  Toda e qualquer informação das suas indicações e/ou premiações
                  deverá ser acompanhado pelo usuário através do whatsapp.
                </li>
              </ul>
            </fieldset>
          </div>
        </div>
      </Modal>
    </>
  )
}
