import React from 'react'

import { Pagination as MuiPagination } from '@material-ui/lab'
import './styles.css'

export const Pagination = ({ page, setPage, totalPages }) => {
  return (
    <div className='pagination-container'>
      <div className='pagination-content'>
        <MuiPagination
          count={Math.ceil(totalPages)}
          color='primary'
          onChange={(e, page) => setPage(page)}
          page={page}
          siblingCount={window.screen.width < 600 ? 0 : 1}
        />
      </div>
    </div>
  )
}
