const values = {
  comprar: [
    { value: 100000, option: 'Até R$ 100.000,00' },
    { value: 200000, option: 'Até R$ 200.000,00' },
    { value: 300000, option: 'Até R$ 300.000,00' },
    { value: 400000, option: 'Até R$ 400.000,00' },
    { value: 500000, option: 'Até R$ 500.000,00' },
    { value: 600000, option: 'Até R$ 600.000,00' },
    { value: 600000, option: 'Até R$ 700.000,00' },
    { value: 800000, option: 'Até R$ 800.000,00' },
    { value: 900000, option: 'Até R$ 900.000,00' },
    { value: 1000000, option: 'Até R$ 1.000.000,00' },
    { value: 1100000, option: 'Até R$ 1.100.000,00' },
    { value: 1200000, option: 'Até R$ 1.200.000,00' },
    { value: 1300000, option: 'Até R$ 1.300.000,00' },
    { value: 1400000, option: 'Até R$ 1.400.000,00' },
    { value: 1500000, option: 'Até R$ 1.500.000,00' },
    { value: 1600000, option: 'Até R$ 1.600.000,00' },
    { value: 1700000, option: 'Até R$ 1.700.000,00' },
    { value: 1800000, option: 'Até R$ 1.800.000,00' },
    { value: 1900000, option: 'Até R$ 1.900.000,00' },
    { value: 2000000, option: 'Até R$ 2.000.000,00' },
  ],
  alugar: [
    { value: 300, option: 'Até R$ 300,00' },
    { value: 500, option: 'Até R$ 500,00' },
    { value: 700, option: 'Até R$ 700,00' },
    { value: 900, option: 'Até R$ 900,00' },
    { value: 1100, option: 'Até R$ 1.100,00' },
    { value: 1300, option: 'Até R$ 1.300,00' },
    { value: 1500, option: 'Até R$ 1.500,00' },
    { value: 1700, option: 'Até R$ 1.700,00' },
    { value: 1900, option: 'Até R$ 1.900,00' },
    { value: 2000, option: 'Até R$ 2.000,00' },
    { value: 3000, option: 'Até R$ 3.000,00' },
    { value: 5000, option: 'Até R$ 5.000,00' },
  ],
}

export default values
