import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { FaHeart } from "react-icons/fa";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import logo from "assets/logo.png";

import "./Styles.css";

export const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const [numeros, setNumeros] = useState('');
  
  useEffect(() => {
    let favoritesArray = localStorage.getItem("favorites")?.split(",") || [];
    if (favoritesArray.length > 0 && favoritesArray[0] === "") {
      favoritesArray = favoritesArray.slice(1);
    }
    const numero = favoritesArray.length
    setNumeros(numero)
  }, []);
   
  return (
    <header className="header-container">
      <nav className="header-content">
        <Link
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          to="/"
        >
          <img src={logo} alt="logo" className="header-log-img" />
        </Link>
        {window.screen.width < 600 ? (
          <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
            <MenuItem
                onClick={() => (window.location.href = "https://calculadoradevendaideal.com.br/")}
              >
                Calculadora Ideal
              </MenuItem> 
              <MenuItem onClick={() => (window.location.href = "/anunciar")}>
                Anunciar Imóvel
              </MenuItem>
              <MenuItem
                onClick={() => (window.location.href = "https://forms.gle/GZ6bSEjGkXfYuzVN7")}
              >
                Trabalhe Conosco
              </MenuItem>  

              <MenuItem onClick={() => (window.location.href = "/#contato")}>
                Contato
              </MenuItem>
              <MenuItem onClick={() => (window.location.href = "/indica-ai")}>
                Indica aí
              </MenuItem>
              <MenuItem
                onClick={() =>
                  (window.location.href = "https://blog.idealni.com.br/")
                }
              >
                Blog
              </MenuItem>
              <MenuItem
                onClick={() =>
                  (window.location.href =
                    "http://portalunsoft.com.br/area-do-cliente/idealni")
                }
              >
                Entrar
              </MenuItem>
              <MenuItem onClick={() => (window.location.href = "/favoritos")}>
                Favoritos
              </MenuItem>
            </Menu>
          </>
        ) : (
          <ul className="header-links">
            <li>
              <a href="https://calculadoradevendaideal.com.br/">Calculadora Ideal</a>
            </li>
            <li>
              <Link to="/anunciar">Anunciar Imóvel</Link>
            </li>
            <li>
              <a href="https://forms.gle/GZ6bSEjGkXfYuzVN7">Trabalhe Conosco</a>
            </li>
            <li>
              <a href="/#contato">Contato</a>
            </li>
            <li>
              <Link to="/indica-ai">Indica aí</Link>
            </li>
            <li>
              <a href="https://blog.idealni.com.br/">Blog</a>
            </li>
            <li>
              <a
                className="entrar"
                href="http://portalunsoft.com.br/area-do-cliente/idealni"
              >
                Entrar
              </a>
            </li>
            <li>
              <Link className="amar" to="/favoritos">
                <FaHeart
                  className="amar"
                  style={{
                    color:
                      location.pathname === "/favoritos" ? "#7e1c1e" : "black",
                  }}
                  size={25}
                />
                <div className="iconsTypes">
                <span className="numeros-favoritos">{numeros}</span>
                </div>
                
              </Link>
            </li>
          </ul>
        )}
      </nav>
    </header>
  );
};
