const { FaFileImage, FaMap, FaStreetView, FaPlay } = require("react-icons/fa");

const dataItensChoose = [
    {
        id: 1,
        title: 'Fotos',
        type: 'foto',
        active: true,
        icon: FaFileImage
    },
    {
        id: 2,
        title: 'Video',
        type: 'video',
        active: false,
        icon: FaPlay
    },
    {
        id: 3,
        title: 'Mapa',
        type: 'map',
        active: false,
        icon: FaMap
    },
    // {
    //     id: 4,
    //     title: 'Rua',
    //     type: 'street',
    //     active: false,
    //     icon: FaStreetView
    // },
]

export default dataItensChoose;