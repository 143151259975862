import { useState, useEffect, useCallback } from 'react'
import request from 'request'
import moment from 'moment'
import validate from 'validate.js'
import swal from 'sweetalert'

const schema = {
  fullName: {
    presence: { allowEmpty: false, message: () => 'Este campo é obrigatório' },
    length: { maximum: 100, tooLong: 'Máximo de 100 caracteres' },
  },
  phone: {
    presence: { allowEmpty: false, message: () => 'Este campo é obrigatório' },
    length: { is: 11, message: 'Insira um número válido' },
  },
  email: {
    presence: { allowEmpty: false, message: () => 'Este campo é obrigatório' },
    email: { message: () => 'Insira um email válido' },
  },
}

const initialFormData = {
  isValid: false,
  values: {
    date: new Date(),
    hour: '',
    fullName: '',
    email: '',
    phone: '',
    returnType: 'phone',
  },
  touched: {
    date: true,
    hour: true,
    fullName: false,
    email: false,
    phone: false,
    returnType: true,
  },
  errors: {
    date: [],
    hour: [],
    fullName: [],
    email: [],
    phone: [],
    returnType: [],
  },
}

export const useHooks = (code, finalidade) => {
  const [step, setStep] = useState('date')
  const [hoursOptions, setHoursOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState(initialFormData)

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const steps = [
    { label: 'Data/Hora', value: 'date' },
    { label: 'Dados de contato', value: 'contact' },
  ]

  const handleChange = (name, value) => {
    setForm((form) => ({
      ...form,
      values: {
        ...form.values,
        [name]: value,
      },
      touched: {
        ...form.touched,
        [name]: true,
      },
    }))
  }

  const handleCancel = () => {
    handleClose()
    setForm(initialFormData)
    setStep('date')
  }

  const handleSchedule = async (e) => {
    e.preventDefault()

    if (!form.isValid)
      return setForm((form) => ({
        ...form,
        touched: {
          ...form.touched,
          fullName: true,
          phone: true,
          email: true,
        },
      }))

    try {
      await request('POST', 'Lead/IncluirAgendamentoVisita', {
        nome: form.values.fullName,
        telefone: form.values.phone,
        email: form.values.email,
        finalidade: finalidade === 'Aluguel' ? 1 : 2,
        codigoimovel: `${code}`,
        codigounidade: '4449',
        datahoraagendamentovisita: `${moment(form.values.date).format(
          'DD/MM/YYYY'
        )} ${form.values.hour}`,
        midia: 'Site Imobiliária',
        anotacoes: `Link: ${window.location.href}, Celular: ${form.values.phone}, Prefere ser retornado por: ${form.values.returnType} `,
      })

      swal({
        icon: 'success',
        title: 'Sucesso',
        text: 'Visita agendada com sucesso',
        timer: 1500,
      })
    } catch (ex) {
      swal({
        icon: 'error',
        title: 'Ops...',
        text: ex.response.data || 'Erro ao agendar. Tente novamente.',
      })
    }

    handleClose()
    setForm(initialFormData)
    setStep('date')
  }

  const getAvailableHours = useCallback(async () => {
    const hours = await request(
      'GET',
      '/Imovel/RetornarHorariosVisitasDisponiveis',
      {
        codigoimovel: code,
        data: moment(form.values.date).format('DD/MM/YYYY'),
      }
    )
    setHoursOptions(hours)
  }, [code, form.values.date])

  useEffect(() => {
    const errors = validate(form.values, schema, { fullMessages: false })
    setForm((form) => ({
      ...form,
      isValid: !errors,
      errors: errors || {},
    }))
  }, [form.values])

  const hasError = (name) => Boolean(form?.touched[name] && form?.errors[name])

  useEffect(() => {
    setLoading(true)
    getAvailableHours()
    setLoading(false)
  }, [getAvailableHours, form.values.date])

  return {
    step,
    setStep,
    hoursOptions,
    loading,
    handleOpen,
    handleClose,
    open,
    steps,
    handleChange,
    form,
    handleCancel,
    handleSchedule,
    hasError,
  }
}
