import React, { useState, useEffect } from "react";
import request from "request";

import swal from "sweetalert";
import { Header, Footer, Loading } from "Components";
import { EmptyPage } from "./components/EmptyPage";
import { FavoriteCard } from "./components/FavoriteCard";

import "./styles.css";
import Seo from "helpers/tools/Seo";

const Favorites = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);

  const getProperties = async (favorites) => {
    setLoading(true);
    if (favorites) {
      const properties = await request(
        "GET",
        "Imovel/RetornarImoveisDisponiveis",
        {
          codigosimoveis: favorites,
          numeroPagina: 1,
          numeroRegistros: 20,
        }
      );

      setProperties(properties.lista);
      setLoading(false);
    } else {
      setProperties([]);
      setLoading(false);
    }
  };

  const removeFavorite = (property) => {
    swal({
      icon: "warning",
      title: "Alerta",
      text: `Deseja mesmo remover ${property.titulo} da lista de favoritos?`,
      buttons: ["Cancelar", "Remover"],
    }).then((remove) => {
      if (remove) {
        const newFavorites =
          localStorage?.favorites?.length > 0
            ? localStorage?.favorites?.split(",")
            : [];
        newFavorites.splice(newFavorites.indexOf(`${property?.codigo}`), 1);
        localStorage.setItem("favorites", newFavorites.join(","));
        getProperties(newFavorites.join(","));
      }
    });
  };

  useEffect(() => {
    getProperties(localStorage.favorites);
  }, []);

  return (
    <>
      <Seo
        title="Ideal - Favoritos"
        description="Que tal favoritar alguns imóveis para ver depois?"
        titleOg="Ideal - Imovéis favoritos"
      />
      <Header />
      <div className="favorites-container">
        <div className="favorites-content">
          <div className="favorites-title-container">
            <h1>Seus Imóveis favoritos</h1>
          </div>
          <div className="favorites-properties-container">
            {loading && <Loading />}
            {properties?.length === 0 && !loading && <EmptyPage />}
            {properties?.length > 0 &&
              !loading &&
              properties.map((property) => (
                <FavoriteCard
                  key={property?.codigo}
                  removeFavorite={removeFavorite}
                  property={property}
                />
              ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Favorites;
