import { useState, useEffect } from "react";
import swal from "sweetalert";

const useHooks = () => {
  const [option, setOption] = useState("Imagens");
  const [favoritesList, setFavoritesList] = useState([]);
  const addFavorite = (data) => {
    
    const storedFavorites = localStorage.getItem("favorites");
    const favoritesArray = storedFavorites ? storedFavorites.split(",") : [];

    if (favoritesArray.includes(`${data?.codigo}`)) {
      return swal({
        title: "Imóvel já foi adicionado aos favoritos",
        icon: "warning",
      });
    }

    favoritesArray.push(`${data?.codigo}`);
    localStorage.setItem("favorites", favoritesArray.join(","));
    setFavoritesList(favoritesArray);
    swal({
      title: "Imóvel adicionado aos favoritos",
      icon: "success",
      timer: 1500,
    });
  };
  

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        url: window.location.href,
        title: "Compartihar uma propriedade",
        text: "Olá, veja essa propriedade em: ",
      });
    } else {
      navigator.clipboard.writeText(window.location.href);
      swal({
        title: "URL copiada para área de transferência",
        icon: "success",
        timer: 2000,
      });
    }
  };

  useEffect(() => {
    const favorites = localStorage.getItem("favorites") || "";
    setFavoritesList(favorites?.split(","));
  }, []);

  return { option, setOption, addFavorite, favoritesList, handleShare };
};

export default useHooks;
