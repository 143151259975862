import React from "react";

import "./styles.css";
import { FiCheck } from "react-icons/fi";

export const Features = ({ property, type = "internal" }) => {
  const features = {
    internal: [
      { label: "Ar condicionado", have: property.arcondicionado },
      { label: "Ármario no banheiro", have: property.armariobanheiro },
      { label: "Box no banheiro", have: property.box },
      { label: "Despensa", have: property.despensa },
      { label: "Mobiliado", have: property.mobiliado },
      { label: "Vista para o mar", have: property.vistamar },
      { label: "Cabeamento estruturado", have: property.cabeamentoestruturado },
      { label: "Vista para montanha", have: property.vistamontanha },
      { label: "Área privativa", have: property.areaprivativa },
      { label: "Ármario na cozinha", have: property.armariocozinha },
      { label: "Closet", have: property.closet },
      { label: "Escritório", have: property.escritorio },
      { label: "Rouparia", have: property.rouparia },
      { label: "Varanda Gourmet", have: property.varandagourmet },
      { label: "Vista para lago", have: property.vistalago },
      { label: "Área serviço ", have: property.areaservico },
      { label: "Armário quarto", have: property.armarioquarto },
      { label: "DCE", have: property.dce },
      { label: "Lavabo", have: property.lavabo },
      { label: "Sol da manhã", have: property.solmanha },
      { label: "Lareira", have: property.lareira },
      { label: "Conexão internet", have: property.conexaointernet },
      { label: "Sauna", have: property.sauna },
      { label: "TV a cabo", have: property.tvacabo },
    ],
    external: property.numeroelevador !== "0" ? [
      { label: "Água individual", have: property.aguaindividual },
      { label: "Aquec. elétrico", have: property.aquecedoreletrico },
      { label: "Aquec. solar", have: property.aquecedorsolar },
      { label: "Aquec. gás", have: property.aquecedorgas },
      { label: "Gás canalizado", have: property.gascanalizado },
      { label: "Cerca elétrica", have: property.cercaeletrica },
      { label: "Jardim", have: property.jardim },
      { label: "Portão eletrônico", have: property.portaoeletronico },
      { label: "Alarme", have: property.alarme },
      { label: "Box despejo", have: property.boxdespejo },
      { label: "Circuito TV", have: property.circuitotv },
      { label: "Interfone", have: property.interfone },
      { label: "Elevador", have: property.numeroelevador },
      { label: "Portaria 24 horas", have: property.portaria24horas },
      { label: "Quintal", have: property.quintal }
    ] : [
      { label: "Água individual", have: property.aguaindividual },
      { label: "Aquec. elétrico", have: property.aquecedoreletrico },
      { label: "Aquec. solar", have: property.aquecedorsolar },
      { label: "Aquec. gás", have: property.aquecedorgas },
      { label: "Gás canalizado", have: property.gascanalizado },
      { label: "Cerca elétrica", have: property.cercaeletrica },
      { label: "Jardim", have: property.jardim },
      { label: "Portão eletrônico", have: property.portaoeletronico },
      { label: "Alarme", have: property.alarme },
      { label: "Box despejo", have: property.boxdespejo },
      { label: "Circuito TV", have: property.circuitotv },
      { label: "Interfone", have: property.interfone },
      { label: "Portaria 24 horas", have: property.portaria24horas },
      { label: "Quintal", have: property.quintal }
    ],

   
    extra: [{ label: "Área externa", have: property.areaexterna }],
  };

  return (
    <div className="internal-features">
      {features[type].map((item) => (
        <div key={item.label} className="internal-features-item">
          <span>{item.have && <FiCheck size={18} color="#F18C1F" />}</span>
          <p className={item.have ? "" : "dont-have"}>{item.label}</p>
        </div>
      ))}
    </div>
  );
};
