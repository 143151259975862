import Form from "react-bootstrap/Form";
import { useCallback, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { useFormik } from "formik";
import * as Yup from "yup";
import request from "request";
import "./styles.css";

function StaticExample({ openModal, onClose }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  const closeModal = () => {
    setOpen(false);
    onClose();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nome é obrigatório"),
    email: Yup.string().email("Email inválido").required("Email é obrigatório"),
    phone: Yup.number().required("Telefone é obrigatório"),
    finalidade: Yup.string().required("Selecione a finalidade"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      finalidade: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      send(values);
      const message = "Olá. Gostaria da ajuda de um especialista para encontrar meu imóvel Ideal."
      window.open(
        `https://api.whatsapp.com/send?phone=5531989531549&text=${message}`,
        "_blank"
      );
      formik.resetForm();
      onClose();
    },
  });

  const send = useCallback(async (data) => {
    const datasFinalidade = data.finalidade === "0" ? "ALUGUEL" : "VENDA"
    const testes = {
      nome: data.name,
      email: data.email,
      telefone: data.phone,
      codigoimovel: null,
      midia: "SITE",
      finalidade: data.finalidade,
    }
    const hours = await request("POST", "/Lead/IncluirLead", testes);
  }, []);

  return (
    <>
      <Popup open={open} closeOnDocumentClick onClose={onClose}>
        <div className="modal modalBack">
          <div
            style={{
              background: "#095d52",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 10,
              }}
            >
              <div className="imgIdeal"></div>
              <div style={{ marginLeft: 20 }}>
                <h3 style={{ color: "white", margin: 0 }}>Ideal Negócios</h3>
                <p style={{ color: "white", margin: 0 }}>Online</p>
              </div>
            </div>

            <a className="close closeModals" onClick={closeModal}>
              &times;
            </a>
          </div>
          <div style={{ padding: 10 }}>
            <div className="dialogModa">
              <p>
                Olá, você deseja a ajuda de um especialista para encontrar o seu
                imóvel?
              </p>
            </div>
          </div>

          <div style={{ padding: 10, display: "flex", justifyContent: "end" }}>
            <form onSubmit={formik.handleSubmit} className="formsIn">
              <Form.Group
                className="mb-3"
                style={{ marginBottom: 15 }}
                controlId="exampleForm.ControlInput1"
              >
                <input
                  className="inputControls"
                  type="text"
                  id="name"
                  placeholder="Nome*"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <p
                    className="errors"
                    style={{ color: "red", margin: 0, fontSize: 12 }}
                  >
                    {formik.errors.name}
                  </p>
                ) : null}
              </Form.Group>

              <Form.Group
                className="mb-3"
                style={{ marginBottom: 15 }}
                controlId="exampleForm.ControlInput2"
              >
                <input
                  className="inputControls"
                  type="email"
                  id="email"
                  placeholder="Email*"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p
                    className="errors"
                    style={{ color: "red", margin: 0, fontSize: 12 }}
                  >
                    {formik.errors.email}
                  </p>
                ) : null}
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput3"
              >
                <input
                  className="inputControls"
                  type="number"
                  id="phone"
                  name="phone"
                  placeholder="Telefone*"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <p
                    className="errors"
                    style={{ color: "red", margin: 0, fontSize: 12 }}
                  >
                    {formik.errors.phone}
                  </p>
                ) : null}
              </Form.Group>

              <Form.Select
                className="inputSelect2"
                aria-label="Default select example"
                id="finalidade"
                name="finalidade"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.finalidade}
              >
                <option>Selecionar finalidade*</option>
                <option value="1">Aluguel</option>
                <option value="0">Venda</option>
              </Form.Select>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: 15,
                }}
              >
                <button type="submit" className="buttonWpp">
                  Iniciar conversa
                </button>
              </div>
            </form>
          </div>
        </div>
      </Popup>
    </>
  );
}

export default StaticExample;
