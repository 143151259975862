import React from "react";

import ScrollAnimation from "react-animate-on-scroll";

import "./Styles.css";
import busque from "assets/busque.png";
import agende from "assets/agende.png";
import feche from "assets/feche.png";
import cuidamos from "assets/cuidamos.png";
import comodidade from "../../../../assets/comodidade.svg";
import Segurança from "../../../../assets/segurança.svg";
import tranquilidade from "../../../../assets/tranquilidade.svg";
import burocracia from "../../../../assets/sem-burocracia.svg";

const Section4 = () => {
  const sectionItem = [
    {
      title: "Comodidade",
      icon: comodidade,
      text: <p>Seu imóvel na palma da sua mão.Clicou e resolveu.</p>,
      imgSrc: busque,
      imgAlt: "Busque",
      animate: "bounceInLeft",
    },
    {
      title: "Segurança ",
      icon: Segurança,
      text: (
        <p>
          Todos os processos de venda, locação e financiamento com 100% de
          segurança e confiabilidade.
        </p>
      ),
      imgSrc: agende,
      imgAlt: "Agendas",
      animate: "bounceInRight",
      reverse: true,
    },
    {
      title: "Tranquilidade",
      icon: tranquilidade,
      text: (
        <p>
          Cuidamos de todos os processos do início ao fim com profissionalismo
        </p>
      ),
      imgSrc: feche,
      imgAlt: "Mãos apertando",
      animate: "bounceInLeft",
    },
    {
      title: "Sem burocracia",
      icon: burocracia,
      text: (
        <p>
          Modernidade e processos mais claros e rápidos com a mesma segurança de
          sempre.
        </p>
      ),
      imgSrc: cuidamos,
      imgAlt: "Segurança",
      animate: "bounceInRight",
      reverse: true,
    },
  ];
  return (
    <section className="section-4">
      <div className="section4-content">
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <div className="containerTituloPrincipal">
            <div className="tituloPrinci">
              <h1 className="section4-title">
                Por que é mais fácil comprar ou alugar seu imovel na ideal?
              </h1>
            </div>
          </div>
        </ScrollAnimation>

        <div className="containerCard">
          {sectionItem.map((item) => (
            <div className="cardItem">
              <div className="containerIcon">
                <img className="svgItens" src={item.icon} />
              </div>
              <div className="titleCards">
                <h4>{item.title}</h4>
              </div>
              <div className="textCard">
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section4;
