import React from 'react'

import house from 'assets/house-empty.png'

export const EmptyPage = () => {
  return (
    <div className='favorites-empty-page-container'>
      <h1>Você não possui nenhum imóvel favoritado</h1>
      <img alt='Casas' src={house} />
      <h2>Que tal favoritar alguns imóveis para ver depois?</h2>
    </div>
  )
}
