import React from 'react'
import { useHistory } from 'react-router-dom'

import { IconButton } from '@material-ui/core'

import { MdOutlineBathtub, MdOutlineDirectionsCar } from 'react-icons/md'
import { BiRuler } from 'react-icons/bi'
import { RiHotelBedLine } from 'react-icons/ri'
import { FaTrash } from 'react-icons/fa'

import './styles.css'

export const FavoriteCard = ({ property, removeFavorite }) => {
  const { push } = useHistory()

  const infoIcons = [
    {
      title: 'Banheiro(s)',
      value: property.numerobanhos,
      icon: <MdOutlineBathtub color='#570605' size={25} />,
    },
    {
      title: 'Tamanho',
      value: `${property.areaprincipal} ${property.tipomedida}`,
      icon: <BiRuler color='#570605' size={25} />,
    },
    {
      title: 'Vaga(s)',
      value: property.numerovagas,
      icon: <MdOutlineDirectionsCar color='#570605' size={25} />,
    },
    {
      title: 'Quarto(s)',
      value: property.numeroquartos,
      icon: <RiHotelBedLine color='#570605' size={25} />,
    },
  ]

  const openProperty = () => {
    push(`detalhes/imovel/${property.finalidade}-${property.tipo}-${property.numeroquartos}-quartos-${property.bairro}-${property.cidade}-${property.estado}-${property.codigo}`, property)
  }

  return (
    <div className='favorite-card-item'>
      <div className='favorite-card-item-content'>
        <section className='favorite-card-img-container'>
          <img
            className='favorite-card-img'
            src={property.urlfotoprincipal}
            alt='Imagem da propriedade'
          />
        </section>
        <section
          className='favorite-card-info-container'
          onClick={openProperty}
        >
          <h1>
            {`${property.titulo}, ${property.bairro}, ${property.cidade} - Cód. ${property.codigo}`}
          </h1>

          <div className='favorite-card-type-and-price'>
            <span>{property.finalidade}</span>
            <span>{property.valor}</span>
          </div>
          <div className='favorite-card-icons-container'>
            {infoIcons.map((item) => (
              <div className='favorite-card-icon' key={item.title}>
                {item.icon}
                <span className='favorite-card-text-icon'>{item.title}</span>
                <span className='favorite-card-text-icon'>{item.value}</span>
              </div>
            ))}
          </div>
        </section>
        <IconButton
          onClick={() => {
            removeFavorite(property)
          }}
          className='favorite-card-trash-icon'
        >
          <FaTrash color='#570605' size={20} />
        </IconButton>
      </div>
    </div>
  )
}
