import React, { useState, useEffect } from 'react'

import { Button, TextField } from '@material-ui/core'
import validate from 'validate.js'
import './styles.css'

const schema = {
  fullName: {
    presence: { allowEmpty: false, message: () => 'Este campo é obrigatório' },
    length: { maximum: 100, tooLong: 'Máximo de 100 caracteres' },
  },
  email: {
    presence: { allowEmpty: false, message: () => 'Este campo é obrigatório' },
    email: { message: () => 'Insira um email válido' },
  },
}

const initialFormData = {
  isValid: false,
  values: {
    fullName: '',
    email: '',
  },
  touched: {
    fullName: false,
    email: false,
  },
  errors: {
    fullName: [],
    email: [],
  },
}

export const Step3 = ({ handleSubmit, handleBackStep }) => {
  const [form, setForm] = useState(initialFormData)

  const handleChange = (name, value) => {
    setForm((form) => ({
      ...form,
      values: {
        ...form.values,
        [name]: value,
      },
      touched: {
        ...form.touched,
        [name]: true,
      },
    }))
  }

  const submit = () => {
    if (!form.isValid)
      return setForm((form) => ({
        ...form,
        touched: {
          ...form.touched,
          fullName: true,
          email: true,
        },
      }))

    handleSubmit(form.values)
  }

  useEffect(() => {
    const errors = validate(form.values, schema, { fullMessages: false })
    setForm((form) => ({
      ...form,
      isValid: !errors,
      errors: errors || {},
    }))
  }, [form.values])

  const hasError = (name) => Boolean(form?.touched[name] && form?.errors[name])

  return (
    <div className='step3'>
      <h1>Seus dados</h1>
      <p>
        Para que possamos finalizar a simulação, por favor nos informe alguns
        dados
      </p>
      <div className='step3-input-container'>
        <div className='step3-input-content'>
          <label>Nome Completo</label>
          <TextField
            className='step3-input'
            variant='outlined'
            name='fullName'
            fullWidth
            value={form.values.fullName}
            onChange={(e) => handleChange('fullName', e.target?.value)}
            error={hasError('fullName')}
            helperText={hasError('fullName') && form.errors['fullName'][0]}
          />
        </div>
        <div className='step3-input-content'>
          <label>Email</label>
          <TextField
            className='step3-input'
            variant='outlined'
            name='email'
            value={form.values.email}
            onChange={(e) => handleChange('email', e.target?.value)}
            fullWidth
            error={hasError('email')}
            helperText={hasError('email') && form.errors['email'][0]}
          />
        </div>
      </div>
      <div className='step3-button-container'>
        <Button onClick={handleBackStep} className='step3-button secondary'>
          Voltar
        </Button>
        <Button onClick={submit} className='step3-button'>
          Próximo
        </Button>
      </div>
    </div>
  )
}
