import React from 'react'

import {
  Button,
  Slider,
  Select,
  MenuItem,
  FormControl,
  Chip,
  Checkbox,
  ListItemText,
} from '@material-ui/core'
import { IconButton, Modal } from '@material-ui/core'
import { FiSearch } from 'react-icons/fi'

import { parseMoney, getLabel } from 'helpers/utils'
import './styles.css'

const FilterModal = ({
  open,
  setOpen,
  form,
  type,
  setType,
  handleChange,
  neighborhoods,
  cities,
  types,
  canShowBadroomAndBathRoomFilters,
}) => {
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const options = [
    { name: '1+', value: 1 },
    { name: '2+', value: 2 },
    { name: '3+', value: 3 },
    { name: '4+', value: 4 },
  ]
  return (
    <>
      <IconButton onClick={handleOpen} className='rent-filterButton'>
        <FiSearch size={25} color='#fff' />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <div className='rent-filter-modal'>
          <div className='rent-filter-content-container'>
            <div className='rent-filter-button-container'>
              <Button
                onClick={() => {
                  setType('alugar')
                }}
                className={`rent-filter-rent-button ${
                  type === 'alugar' ? 'selected' : ''
                }`}
              >
                Alugar
              </Button>
              <Button
                onClick={() => {
                  setType('comprar')
                }}
                className={`rent-filter-purchase-button ${
                  type === 'comprar' ? 'selected' : ''
                }`}
              >
                Comprar
              </Button>
            </div>
            <div className='rent-filter-input'>
              <label>Código</label>
              <input
                type='text'
                value={form.code}
                onChange={(e) => handleChange(e.target?.value, 'code')}
              />
            </div>
            <div className='rent-filter-input'>
              <label>Localização</label>
              <FormControl fullWidth>
                <Select
                  className='rent-filter-select-input'
                  value={form.city}
                  disableUnderline
                  onChange={(e) => handleChange(e.target?.value, 'city')}
                >
                  {cities.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {form?.city && form.city !== '' && (
              <div className='rent-filter-input'>
                <label>Bairro</label>
                <FormControl fullWidth>
                  <Select
                    className='sidebar-select-input'
                    value={form.neighborhood}
                    disableUnderline
                    renderValue={(selected) =>
                      getLabel(neighborhoods, selected)
                    }
                    multiple
                    onChange={(e) =>
                      handleChange(e.target?.value, 'neighborhood')
                    }
                  >
                    {neighborhoods.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        <Checkbox
                          color='primary'
                          checked={form.neighborhood?.indexOf(item.value) > -1}
                        />
                        <ListItemText>{item.option}</ListItemText>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            <div className='rent-filter-input'>
              <label>Valor do imóvel</label>
              <div className='rent-filter-property-value'>
                <span>De {`${parseMoney(form.value[0])}`}</span>
                <span>Até {`${parseMoney(form.value[1])}`}</span>
              </div>
              <Slider
                value={form.value}
                defaultValue={form.value}
                onChange={(e, value) => handleChange(value, 'value')}
                color='primary'
                step={type === 'alugar' ? 200 : 20000}
                max={type === 'alugar' ? 20000 : 2000000}
                min={0}
              />
            </div>
            <div className='rent-filter-input'>
              <label>Tipo de imóvel</label>
              {types.map((type) => (
                <Chip
                  className='rent-filter-chip'
                  key={type.value}
                  label={type.option}
                  color={
                    form.types.indexOf(type.value) > -1
                      ? 'primary'
                      : 'secondary'
                  }
                  onClick={() => handleChange(type.value, 'types')}
                />
              ))}
            </div>
            {canShowBadroomAndBathRoomFilters && (
              <>
                <div className='rent-filter-input'>
                  <label>Banheiros</label>
                  <div className='rent-filter-chip-row-container'>
                    {options.map((item) => (
                      <Chip
                        className='rent-filter-chip-circle'
                        key={item.value}
                        label={item.name}
                        color={
                          form.bathrooms === item.value
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={() => handleChange(item.value, 'bathrooms')}
                      />
                    ))}
                  </div>
                </div>
                <div className='rent-filter-input'>
                  <label>Quartos</label>
                  <div className='rent-filter-chip-row-container'>
                    {options.map((item) => (
                      <Chip
                        className='rent-filter-chip-circle'
                        key={item.value}
                        label={item.name}
                        style={{
                          color:
                            form.bedrooms === item.value ? '#fff' : '#3F0B0B',
                        }}
                        color={
                          form.bedrooms === item.value ? 'primary' : 'secondary'
                        }
                        onClick={() => handleChange(item.value, 'bedrooms')}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
            <div className='rent-filter-action-button-container'>
              <Button onClick={handleClose}>Fechar</Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default FilterModal
