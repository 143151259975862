import React from 'react'
import { useHistory } from 'react-router-dom'

import { Header, Footer } from 'Components'
import { Step1, Step2, Step3 } from './components'
import { useHooks } from './hooks'
import { Stepper, Step, StepLabel } from '@material-ui/core'

import './styles.css'

const SimulateFinancing = () => {
  const { location } = useHistory()
  const { step, steps, handleSubmit, handleBackStep } = useHooks()

  return (
    <>
      <Header />
      <div className='simulate-container'>
        <div className='simulate-content'>
          <h1 className='title'>Simule seu financiamento</h1>
          <Stepper className='simulate-stepper' activeStep={step - 1}>
            {steps.map((item, i) => (
              <Step key={i}>
                <StepLabel>{item.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div className='simulate-steps-container'>
            {step === 1 && <Step1 handleSubmit={handleSubmit} />}
            {step === 2 && (
              <Step2
                handleSubmit={handleSubmit}
                handleBackStep={handleBackStep}
                property={location.state?.property}
              />
            )}
            {step === 3 && (
              <Step3
                handleSubmit={handleSubmit}
                handleBackStep={handleBackStep}
                property={location.state?.property}
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SimulateFinancing
