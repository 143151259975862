const tagsSeo = [
  "Amoras aluguel casa",
  "Barra aluguel casa",
  "Barrinha aluguel casa",
  "Bela Vista aluguel casa",
  "Bom Jesus aluguel casa",
  "Cidade Nova aluguel casa",
  "Clélia Bernardes aluguel casa",
  "Fátima aluguel casa",
  "Inácio Martins aluguel casa",
  "João Braz aluguel casa",
  "Lourdes aluguel casa",
  "Nova Era aluguel casa",
  "Nova Viçosa aluguel casa",
  "Novo Silvestre aluguel casa",
  "Piedade aluguel casa",
  "Posses aluguel casa",
  "Ramos aluguel casa",
  "Recanto da Serra aluguel casa",
  "Santa Clara aluguel casa",
  "Santa Cecília aluguel casa",
  "Santa Rita aluguel casa",
  "São José aluguel casa",
  "São Sebastião aluguel casa",
  "Santo Antônio aluguel casa",
  "Silvestre aluguel casa",
  "Vale do Sol aluguel casa",
  "Violeira aluguel casa",
  "Vila Giannetti aluguel casa",
  "Vila Recanto das Águas aluguel casa",
  "Amoras aluguel apartamento",
  "Barra aluguel apartamento",
  "Barrinha aluguel apartamento",
  "Bela Vista aluguel apartamento",
  "Bom Jesus aluguel apartamento",
  "Cidade Nova aluguel apartamento",
  "Clélia Bernardes aluguel apartamento",
  "Fátima aluguel apartamento",
  "Inácio Martins aluguel apartamento",
  "João Braz aluguel apartamento",
  "Lourdes aluguel apartamento",
  "Nova Era aluguel apartamento",
  "Nova Viçosa aluguel apartamento",
  "Novo Silvestre aluguel apartamento",
  "Piedade aluguel apartamento",
  "Posses aluguel apartamento",
  "Ramos aluguel apartamento",
  "Recanto da Serra aluguel apartamento",
  "Santa Clara aluguel apartamento",
  "Santa Cecília aluguel apartamento",
  "Santa Rita aluguel apartamento",
  "São José aluguel apartamento",
  "São Sebastião aluguel apartamento",
  "Santo Antônio aluguel apartamento",
  "Silvestre aluguel apartamento",
  "Vale do Sol aluguel apartamento",
  "Violeira aluguel apartamento",
  "Vila Giannetti aluguel apartamento",
  "Vila Recanto das Águas aluguel apartamento",
  "Amoras aluguel terreno",
  "Barra aluguel terreno",
  "Barrinha aluguel terreno",
  "Bela Vista aluguel terreno",
  "Bom Jesus aluguel terreno",
  "Cidade Nova aluguel terreno",
  "Clélia Bernardes aluguel terreno",
  "Fátima aluguel terreno",
  "Inácio Martins aluguel terreno",
  "João Braz aluguel terreno",
  "Lourdes aluguel terreno",
  "Nova Era aluguel terreno",
  "Nova Viçosa aluguel terreno",
  "Novo Silvestre aluguel terreno",
  "Piedade aluguel terreno",
  "Posses aluguel terreno",
  "Ramos aluguel terreno",
  "Recanto da Serra aluguel terreno",
  "Santa Clara aluguel terreno",
  "Santa Cecília aluguel terreno",
  "Santa Rita aluguel terreno",
  "São José aluguel terreno",
  "São Sebastião aluguel terreno",
  "Santo Antônio aluguel terreno",
  "Silvestre aluguel terreno",
  "Vale do Sol aluguel terreno",
  "Violeira aluguel terreno",
  "Vila Giannetti aluguel terreno",
  "Vila Recanto das Águas aluguel terreno",
  "Amoras aluguel lote",
  "Barra aluguel lote",
  "Barrinha aluguel lote",
  "Bela Vista aluguel lote",
  "Bom Jesus aluguel lote",
  "Cidade Nova aluguel lote",
  "Clélia Bernardes aluguel lote",
  "Fátima aluguel lote",
  "Inácio Martins aluguel lote",
  "João Braz aluguel lote",
  "Lourdes aluguel lote",
  "Nova Era aluguel lote",
  "Nova Viçosa aluguel lote",
  "Novo Silvestre aluguel lote",
  "Piedade aluguel lote",
  "Posses aluguel lote",
  "Ramos aluguel lote",
  "Recanto da Serra aluguel lote",
  "Santa Clara aluguel lote",
  "Santa Cecília aluguel lote",
  "Santa Rita aluguel lote",
  "São José aluguel lote",
  "São Sebastião aluguel lote",
  "Santo Antônio aluguel lote",
  "Silvestre aluguel lote",
  "Vale do Sol aluguel lote",
  "Violeira aluguel lote",
  "Vila Giannetti aluguel lote",
  "Vila Recanto das Águas aluguel lote",
  "Amoras aluguel chácara",
  "Barra aluguel chácara",
  "Barrinha aluguel chácara",
  "Bela Vista aluguel chácara",
  "Bom Jesus aluguel chácara",
  "Cidade Nova aluguel chácara",
  "Clélia Bernardes aluguel chácara",
  "Fátima aluguel chácara",
  "Inácio Martins aluguel chácara",
  "João Braz aluguel chácara",
  "Lourdes aluguel chácara",
  "Nova Era aluguel chácara",
  "Nova Viçosa aluguel chácara",
  "Novo Silvestre aluguel chácara",
  "Piedade aluguel chácara",
  "Posses aluguel chácara",
  "Ramos aluguel chácara",
  "Recanto da Serra aluguel chácara",
  "Santa Clara aluguel chácara",
  "Santa Cecília aluguel chácara",
  "Santa Rita aluguel chácara",
  "São José aluguel chácara",
  "São Sebastião aluguel chácara",
  "Santo Antônio aluguel chácara",
  "Silvestre aluguel chácara",
  "Vale do Sol aluguel chácara",
  "Violeira aluguel chácara",
  "Vila Giannetti aluguel chácara",
  "Vila Recanto das Águas aluguel chácara",
  "Amoras aluguel cobertura",
  "Barra aluguel cobertura",
  "Barrinha aluguel cobertura",
  "Bela Vista aluguel cobertura",
  "Bom Jesus aluguel cobertura",
  "Cidade Nova aluguel cobertura",
  "Clélia Bernardes aluguel cobertura",
  "Fátima aluguel cobertura",
  "Inácio Martins aluguel cobertura",
  "João Braz aluguel cobertura",
  "Lourdes aluguel cobertura",
  "Nova Era aluguel cobertura",
  "Nova Viçosa aluguel cobertura",
  "Novo Silvestre aluguel cobertura",
  "Piedade aluguel cobertura",
  "Posses aluguel cobertura",
  "Ramos aluguel cobertura",
  "Recanto da Serra aluguel cobertura",
  "Santa Clara aluguel cobertura",
  "Santa Cecília aluguel cobertura",
  "Santa Rita aluguel cobertura",
  "São José aluguel cobertura",
  "São Sebastião aluguel cobertura",
  "Santo Antônio aluguel cobertura",
  "Silvestre aluguel cobertura",
  "Vale do Sol aluguel cobertura",
  "Violeira aluguel cobertura",
  "Vila Giannetti aluguel cobertura",
  "Vila Recanto das Águas aluguel cobertura",
  "Amoras aluguel galpão",
  "Barra aluguel galpão",
  "Barrinha aluguel galpão",
  "Bela Vista aluguel galpão",
  "Bom Jesus aluguel galpão",
  "Cidade Nova aluguel galpão",
  "Clélia Bernardes aluguel galpão",
  "Fátima aluguel galpão",
  "Inácio Martins aluguel galpão",
  "João Braz aluguel galpão",
  "Lourdes aluguel galpão",
  "Nova Era aluguel galpão",
  "Nova Viçosa aluguel galpão",
  "Novo Silvestre aluguel galpão",
  "Piedade aluguel galpão",
  "Posses aluguel galpão",
  "Ramos aluguel galpão",
  "Recanto da Serra aluguel galpão",
  "Santa Clara aluguel galpão",
  "Santa Cecília aluguel galpão",
  "Santa Rita aluguel galpão",
  "São José aluguel galpão",
  "São Sebastião aluguel galpão",
  "Santo Antônio aluguel galpão",
  "Silvestre aluguel galpão",
  "Vale do Sol aluguel galpão",
  "Violeira aluguel galpão",
  "Vila Giannetti aluguel galpão",
  "Vila Recanto das Águas aluguel galpão",
  "Amoras aluguel loja",
  "Barra aluguel loja",
  "Barrinha aluguel loja",
  "Bela Vista aluguel loja",
  "Bom Jesus aluguel loja",
  "Cidade Nova aluguel loja",
  "Clélia Bernardes aluguel loja",
  "Fátima aluguel loja",
  "Inácio Martins aluguel loja",
  "João Braz aluguel loja",
  "Lourdes aluguel loja",
  "Nova Era aluguel loja",
  "Nova Viçosa aluguel loja",
  "Novo Silvestre aluguel loja",
  "Piedade aluguel loja",
  "Posses aluguel loja",
  "Ramos aluguel loja",
  "Recanto da Serra aluguel loja",
  "Santa Clara aluguel loja",
  "Santa Cecília aluguel loja",
  "Santa Rita aluguel loja",
  "São José aluguel loja",
  "São Sebastião aluguel loja",
  "Santo Antônio aluguel loja",
  "Silvestre aluguel loja",
  "Vale do Sol aluguel loja",
  "Violeira aluguel loja",
  "Vila Giannetti aluguel loja",
  "Vila Recanto das Águas aluguel loja",
  "Amoras aluguel prédio",
  "Barra aluguel prédio",
  "Barrinha aluguel prédio",
  "Bela Vista aluguel prédio",
  "Bom Jesus aluguel prédio",
  "Cidade Nova aluguel prédio",
  "Clélia Bernardes aluguel prédio",
  "Fátima aluguel prédio",
  "Inácio Martins aluguel prédio",
  "João Braz aluguel prédio",
  "Lourdes aluguel prédio",
  "Nova Era aluguel prédio",
  "Nova Viçosa aluguel prédio",
  "Novo Silvestre aluguel prédio",
  "Piedade aluguel prédio",
  "Posses aluguel prédio",
  "Ramos aluguel prédio",
  "Recanto da Serra aluguel prédio",
  "Santa Clara aluguel prédio",
  "Santa Cecília aluguel prédio",
  "Santa Rita aluguel prédio",
  "São José aluguel prédio",
  "São Sebastião aluguel prédio",
  "Santo Antônio aluguel prédio",
  "Silvestre aluguel prédio",
  "Vale do Sol aluguel prédio",
  "Violeira aluguel prédio",
  "Vila Giannetti aluguel prédio",
  "Vila Recanto das Águas aluguel prédio",
  "Amoras aluguel sala",
  "Barra aluguel sala",
  "Barrinha aluguel sala",
  "Bela Vista aluguel sala",
  "Bom Jesus aluguel sala",
  "Cidade Nova aluguel sala",
  "Clélia Bernardes aluguel sala",
  "Fátima aluguel sala",
  "Inácio Martins aluguel sala",
  "João Braz aluguel sala",
  "Lourdes aluguel sala",
  "Nova Era aluguel sala",
  "Nova Viçosa aluguel sala",
  "Novo Silvestre aluguel sala",
  "Piedade aluguel sala",
  "Posses aluguel sala",
  "Ramos aluguel sala",
  "Recanto da Serra aluguel sala",
  "Santa Clara aluguel sala",
  "Santa Cecília aluguel sala",
  "Santa Rita aluguel sala",
  "São José aluguel sala",
  "São Sebastião aluguel sala",
  "Santo Antônio aluguel sala",
  "Silvestre aluguel sala",
  "Vale do Sol aluguel sala",
  "Violeira aluguel sala",
  "Vila Giannetti aluguel sala",
  "Vila Recanto das Águas aluguel sala",
  "Amoras aluguel sítio",
  "Barra aluguel sítio",
  "Barrinha aluguel sítio",
  "Bela Vista aluguel sítio",
  "Bom Jesus aluguel sítio",
  "Cidade Nova aluguel sítio",
  "Clélia Bernardes aluguel sítio",
  "Fátima aluguel sítio",
  "Inácio Martins aluguel sítio",
  "João Braz aluguel sítio",
  "Lourdes aluguel sítio",
  "Nova Era aluguel sítio",
  "Nova Viçosa aluguel sítio",
  "Novo Silvestre aluguel sítio",
  "Piedade aluguel sítio",
  "Posses aluguel sítio",
  "Ramos aluguel sítio",
  "Recanto da Serra aluguel sítio",
  "Santa Clara aluguel sítio",
  "Santa Cecília aluguel sítio",
  "Santa Rita aluguel sítio",
  "São José aluguel sítio",
  "São Sebastião aluguel sítio",
  "Santo Antônio aluguel sítio",
  "Silvestre aluguel sítio",
  "Vale do Sol aluguel sítio",
  "Violeira aluguel sítio",
  "Vila Giannetti aluguel sítio",
  "Vila Recanto das Águas aluguel sítio",
  "Amoras aluguel lote em condomínio",
  "Barra aluguel lote em condomínio",
  "Barrinha aluguel lote em condomínio",
  "Bela Vista aluguel lote em condomínio",
  "Bom Jesus aluguel lote em condomínio",
  "Cidade Nova aluguel lote em condomínio",
  "Clélia Bernardes aluguel lote em condomínio",
  "Fátima aluguel lote em condomínio",
  "Inácio Martins aluguel lote em condomínio",
  "João Braz aluguel lote em condomínio",
  "Lourdes aluguel lote em condomínio",
  "Nova Era aluguel lote em condomínio",
  "Nova Viçosa aluguel lote em condomínio",
  "Novo Silvestre aluguel lote em condomínio",
  "Piedade aluguel lote em condomínio",
  "Posses aluguel lote em condomínio",
  "Ramos aluguel lote em condomínio",
  "Recanto da Serra aluguel lote em condomínio",
  "Santa Clara aluguel lote em condomínio",
  "Santa Cecília aluguel lote em condomínio",
  "Santa Rita aluguel lote em condomínio",
  "São José aluguel lote em condomínio",
  "São Sebastião aluguel lote em condomínio",
  "Santo Antônio aluguel lote em condomínio",
  "Silvestre aluguel lote em condomínio",
  "Vale do Sol aluguel lote em condomínio",
  "Violeira aluguel lote em condomínio",
  "Vila Giannetti aluguel lote em condomínio",
  "Vila Recanto das Águas aluguel lote em condomínio",
  "Amoras compra casa",
  "Barra compra casa",
  "Barrinha compra casa",
  "Bela Vista compra casa",
  "Bom Jesus compra casa",
  "Cidade Nova compra casa",
  "Clélia Bernardes compra casa",
  "Fátima compra casa",
  "Inácio Martins compra casa",
  "João Braz compra casa",
  "Lourdes compra casa",
  "Nova Era compra casa",
  "Nova Viçosa compra casa",
  "Novo Silvestre compra casa",
  "Piedade compra casa",
  "Posses compra casa",
  "Ramos compra casa",
  "Recanto da Serra compra casa",
  "Santa Clara compra casa",
  "Santa Cecília compra casa",
  "Santa Rita compra casa",
  "São José compra casa",
  "São Sebastião compra casa",
  "Santo Antônio compra casa",
  "Silvestre compra casa",
  "Vale do Sol compra casa",
  "Violeira compra casa",
  "Vila Giannetti compra casa",
  "Vila Recanto das Águas compra casa",
  "Amoras compra apartamento",
  "Barra compra apartamento",
  "Barrinha compra apartamento",
  "Bela Vista compra apartamento",
  "Bom Jesus compra apartamento",
  "Cidade Nova compra apartamento",
  "Clélia Bernardes compra apartamento",
  "Fátima compra apartamento",
  "Inácio Martins compra apartamento",
  "João Braz compra apartamento",
  "Lourdes compra apartamento",
  "Nova Era compra apartamento",
  "Nova Viçosa compra apartamento",
  "Novo Silvestre compra apartamento",
  "Piedade compra apartamento",
  "Posses compra apartamento",
  "Ramos compra apartamento",
  "Recanto da Serra compra apartamento",
  "Santa Clara compra apartamento",
  "Santa Cecília compra apartamento",
  "Santa Rita compra apartamento",
  "São José compra apartamento",
  "São Sebastião compra apartamento",
  "Santo Antônio compra apartamento",
  "Silvestre compra apartamento",
  "Vale do Sol compra apartamento",
  "Violeira compra apartamento",
  "Vila Giannetti compra apartamento",
  "Vila Recanto das Águas compra apartamento",
  "Amoras compra terreno",
  "Barra compra terreno",
  "Barrinha compra terreno",
  "Bela Vista compra terreno",
  "Bom Jesus compra terreno",
  "Cidade Nova compra terreno",
  "Clélia Bernardes compra terreno",
  "Fátima compra terreno",
  "Inácio Martins compra terreno",
  "João Braz compra terreno",
  "Lourdes compra terreno",
  "Nova Era compra terreno",
  "Nova Viçosa compra terreno",
  "Novo Silvestre compra terreno",
  "Piedade compra terreno",
  "Posses compra terreno",
  "Ramos compra terreno",
  "Recanto da Serra compra terreno",
  "Santa Clara compra terreno",
  "Santa Cecília compra terreno",
  "Santa Rita compra terreno",
  "São José compra terreno",
  "São Sebastião compra terreno",
  "Santo Antônio compra terreno",
  "Silvestre compra terreno",
  "Vale do Sol compra terreno",
  "Violeira compra terreno",
  "Vila Giannetti compra terreno",
  "Vila Recanto das Águas compra terreno",
  "Amoras compra lote",
  "Barra compra lote",
  "Barrinha compra lote",
  "Bela Vista compra lote",
  "Bom Jesus compra lote",
  "Cidade Nova compra lote",
  "Clélia Bernardes compra lote",
  "Fátima compra lote",
  "Inácio Martins compra lote",
  "João Braz compra lote",
  "Lourdes compra lote",
  "Nova Era compra lote",
  "Nova Viçosa compra lote",
  "Novo Silvestre compra lote",
  "Piedade compra lote",
  "Posses compra lote",
  "Ramos compra lote",
  "Recanto da Serra compra lote",
  "Santa Clara compra lote",
  "Santa Cecília compra lote",
  "Santa Rita compra lote",
  "São José compra lote",
  "São Sebastião compra lote",
  "Santo Antônio compra lote",
  "Silvestre compra lote",
  "Vale do Sol compra lote",
  "Violeira compra lote",
  "Vila Giannetti compra lote",
  "Vila Recanto das Águas compra lote",
  "Amoras compra chácara",
  "Barra compra chácara",
  "Barrinha compra chácara",
  "Bela Vista compra chácara",
  "Bom Jesus compra chácara",
  "Cidade Nova compra chácara",
  "Clélia Bernardes compra chácara",
  "Fátima compra chácara",
  "Inácio Martins compra chácara",
  "João Braz compra chácara",
  "Lourdes compra chácara",
  "Nova Era compra chácara",
  "Nova Viçosa compra chácara",
  "Novo Silvestre compra chácara",
  "Piedade compra chácara",
  "Posses compra chácara",
  "Ramos compra chácara",
  "Recanto da Serra compra chácara",
  "Santa Clara compra chácara",
  "Santa Cecília compra chácara",
  "Santa Rita compra chácara",
  "São José compra chácara",
  "São Sebastião compra chácara",
  "Santo Antônio compra chácara",
  "Silvestre compra chácara",
  "Vale do Sol compra chácara",
  "Violeira compra chácara",
  "Vila Giannetti compra chácara",
  "Vila Recanto das Águas compra chácara",
  "Amoras venda casa",
  "Barra venda casa",
  "Barrinha venda casa",
  "Bela Vista venda casa",
  "Bom Jesus venda casa",
  "Cidade Nova venda casa",
  "Clélia Bernardes venda casa",
  "Fátima venda casa",
  "Inácio Martins venda casa",
  "João Braz venda casa",
  "Lourdes venda casa",
  "Nova Era venda casa",
  "Nova Viçosa venda casa",
  "Novo Silvestre venda casa",
  "Piedade venda casa",
  "Posses venda casa",
  "Ramos venda casa",
  "Recanto da Serra venda casa",
  "Santa Clara venda casa",
  "Santa Cecília venda casa",
  "Santa Rita venda casa",
  "São José venda casa",
  "São Sebastião venda casa",
  "Santo Antônio venda casa",
  "Silvestre venda casa",
  "Vale do Sol venda casa",
  "Violeira venda casa",
  "Vila Giannetti venda casa",
  "Vila Recanto das Águas venda casa",
  "Amoras venda apartamento",
  "Barra venda apartamento",
  "Barrinha venda apartamento",
  "Bela Vista venda apartamento",
  "Bom Jesus venda apartamento",
  "Cidade Nova venda apartamento",
  "Clélia Bernardes venda apartamento",
  "Fátima venda apartamento",
  "Inácio Martins venda apartamento",
  "João Braz venda apartamento",
  "Lourdes venda apartamento",
  "Nova Era venda apartamento",
  "Nova Viçosa venda apartamento",
  "Novo Silvestre venda apartamento",
  "Piedade venda apartamento",
  "Posses venda apartamento",
  "Ramos venda apartamento",
  "Recanto da Serra venda apartamento",
  "Santa Clara venda apartamento",
  "Santa Cecília venda apartamento",
  "Santa Rita venda apartamento",
  "São José venda apartamento",
  "São Sebastião venda apartamento",
  "Santo Antônio venda apartamento",
  "Silvestre venda apartamento",
  "Vale do Sol venda apartamento",
  "Violeira venda apartamento",
  "Vila Giannetti venda apartamento",
  "Vila Recanto das Águas venda apartamento",
  "Amoras venda terreno",
  "Barra venda terreno",
  "Barrinha venda terreno",
  "Bela Vista venda terreno",
  "Bom Jesus venda terreno",
  "Cidade Nova venda terreno",
  "Clélia Bernardes venda terreno",
  "Fátima venda terreno",
  "Inácio Martins venda terreno",
  "João Braz venda terreno",
  "Lourdes venda terreno",
  "Nova Era venda terreno",
  "Nova Viçosa venda terreno",
  "Novo Silvestre venda terreno",
  "Piedade venda terreno",
  "Posses venda terreno",
  "Ramos venda terreno",
  "Recanto da Serra venda terreno",
  "Santa Clara venda terreno",
  "Santa Cecília venda terreno",
  "Santa Rita venda terreno",
  "São José venda terreno",
  "São Sebastião venda terreno",
  "Santo Antônio venda terreno",
  "Silvestre venda terreno",
  "Vale do Sol venda terreno",
  "Violeira venda terreno",
  "Vila Giannetti venda terreno",
  "Vila Recanto das Águas venda terreno",
  "Amoras venda lote",
  "Barra venda lote",
  "Barrinha venda lote",
  "Bela Vista venda lote",
  "Bom Jesus venda lote",
  "Cidade Nova venda lote",
  "Clélia Bernardes venda lote",
  "Fátima venda lote",
  "Inácio Martins venda lote",
  "João Braz venda lote",
  "Lourdes venda lote",
  "Nova Era venda lote",
  "Nova Viçosa venda lote",
  "Novo Silvestre venda lote",
  "Piedade venda lote",
  "Posses venda lote",
  "Ramos venda lote",
  "Recanto da Serra venda lote",
  "Santa Clara venda lote",
  "Santa Cecília venda lote",
  "Santa Rita venda lote",
  "São José venda lote",
  "São Sebastião venda lote",
  "Santo Antônio venda lote",
  "Silvestre venda lote",
  "Vale do Sol venda lote",
  "Violeira venda lote",
  "Vila Giannetti venda lote",
  "Vila Recanto das Águas venda lote",
  "Amoras venda chácara",
  "Barra venda chácara",
  "Barrinha venda chácara",
  "Bela Vista venda chácara",
  "Bom Jesus venda chácara",
  "Cidade Nova venda chácara",
  "Clélia Bernardes venda chácara",
  "Fátima venda chácara",
  "Inácio Martins venda chácara",
  "João Braz venda chácara",
  "Lourdes venda chácara",
  "Nova Era venda chácara",
  "Nova Viçosa venda chácara",
  "Novo Silvestre venda chácara",
  "Piedade venda chácara",
  "Posses venda chácara",
  "Ramos venda chácara",
  "Recanto da Serra venda chácara",
  "Santa Clara venda chácara",
  "Santa Cecília venda chácara",
  "Santa Rita venda chácara",
  "São José venda chácara",
  "São Sebastião venda chácara",
  "Santo Antônio venda chácara",
  "Silvestre venda chácara",
  "Vale do Sol venda chácara",
  "Violeira venda chácara",
  "Vila Giannetti venda chácara",
  "Vila Recanto das Águas venda chácara",
  "aluguel casa UFV Viçosa-MG",
  "aluguel apartamento UFV Viçosa-MG",
  "aluguel terreno UFV Viçosa-MG",
  "aluguel lote UFV Viçosa-MG",
  "aluguel chácara UFV Viçosa-MG",
  "aluguel cobertura UFV Viçosa-MG",
  "aluguel galpão UFV Viçosa-MG",
  "aluguel loja UFV Viçosa-MG",
  "aluguel sítio UFV Viçosa-MG",
  "aluguel lote em condomínio UFV Viçosa-MG",
  "venda casa UFV Viçosa-MG",
  "venda apartamento UFV Viçosa-MG",
  "venda terreno UFV Viçosa-MG",
  "venda lote UFV Viçosa-MG",
  "venda chácara UFV Viçosa-MG",
  "venda cobertura UFV Viçosa-MG",
  "venda galpão UFV Viçosa-MG",
  "venda loja UFV Viçosa-MG",
  "venda sítio UFV Viçosa-MG",
  "venda lote em condomínio UFV Viçosa-MG",
  "compra casa UFV Viçosa-MG",
  "compra apartamento UFV Viçosa-MG",
  "compra terreno UFV Viçosa-MG",
  "compra lote UFV Viçosa-MG",
  "compra chácara UFV Viçosa-MG",
  "compra cobertura UFV Viçosa-MG",
  "compra galpão UFV Viçosa-MG",
  "compra loja UFV Viçosa-MG",
  "compra sítio UFV Viçosa-MG",
  "compra lote em condomínio UFV Viçosa-MG",
  "imobiliária Ideal Viçosa-MG UFV",
  "imobiliária Ideal perto da UFV",
  "imóveis para estudantes UFV Viçosa-MG",
  "casas perto da UFV Viçosa-MG",
  "apartamentos perto da UFV Viçosa-MG",
  "terrenos perto da UFV Viçosa-MG",
  "lotes perto da UFV Viçosa-MG",
  "chácara perto da UFV Viçosa-MG",
  "cobertura perto da UFV Viçosa-MG",
  "galpão perto da UFV Viçosa-MG",
  "loja perto da UFV Viçosa-MG",
  "prédio perto da UFV Viçosa-MG",
  "sala perto da UFV Viçosa-MG",
  "sítio perto da UFV Viçosa-MG",
  "lote em condomínio perto da UFV Viçosa-MG",
  "imobiliária Ideal UFV imóveis",
  "imóveis em Viçosa-MG para UFV",
  "imobiliária Ideal Viçosa-MG para estudantes",
  "imóveis próximos à UFV Viçosa-MG",
  "aluguel para estudantes UFV Viçosa-MG",
  "compra de imóveis UFV Viçosa-MG",
  "venda de imóveis UFV Viçosa-MG",
  "imobiliária Ideal imóveis perto da UFV",
  "imóveis na região UFV Viçosa-MG",
  "imobiliária Ideal Viçosa-MG apartamentos próximos à UFV",
  "imobiliária Ideal Viçosa-MG casas para estudantes UFV",
  "ofertas de imóveis UFV Viçosa-MG",
  "imobiliária Ideal Viçosa-MG compra e aluguel UFV",
  "imóveis para alugar perto da UFV Viçosa-MG",
  "imobiliária Ideal Viçosa-MG imóveis para UFV",
  "imobiliária Ideal Viçosa-MG imóveis para famílias UFV",
  "imobiliária Ideal Viçosa-MG imóveis com fácil acesso à UFV",
  "imóveis baratos UFV Viçosa-MG",
  "imobiliária Ideal Viçosa-MG imóveis para todos os perfis",
  "imobiliária Ideal Viçosa-MG imóveis com boas ofertas",
  "imobiliária Ideal Viçosa-MG imóveis com financiamento",
  "imobiliária Ideal Viçosa-MG imóveis novos e usados",
  "imobiliária Ideal Viçosa-MG imóveis com preços acessíveis",
  "imobiliária Ideal Viçosa-MG imóveis para investimento",
  "imobiliária Ideal Viçosa-MG imóveis para primeira compra",
  "imobiliária Ideal Viçosa-MG imóveis em áreas valorizadas",
  "imobiliária Ideal Viçosa-MG imóveis para férias",
  "imobiliária Ideal Viçosa-MG imóveis em bairros tranquilos",
  "imobiliária Ideal Viçosa-MG imóveis com lazer",
  "aluguel casa UFV",
  "aluguel apartamento UFV",
  "aluguel terreno UFV",
  "aluguel lote UFV",
  "aluguel chácara UFV",
  "aluguel cobertura UFV",
  "aluguel galpão UFV",
  "aluguel loja UFV",
  "aluguel sítio UFV",
  "aluguel lote em condomínio UFV",
  "venda casa UFV",
  "venda apartamento UFV",
  "venda terreno UFV",
  "venda lote UFV",
  "venda chácara UFV",
  "venda cobertura UFV",
  "venda galpão UFV",
  "venda loja UFV",
  "venda sítio UFV",
  "venda lote em condomínio UFV",
  "compra casa UFV",
  "compra apartamento UFV",
  "compra terreno UFV",
  "compra lote UFV",
  "compra chácara UFV",
  "compra cobertura UFV",
  "compra galpão UFV",
  "compra loja UFV",
  "compra sítio UFV",
  "compra lote em condomínio UFV",
  "imobiliária Ideal Viçosa",
  "imobiliária Viçosa Ideal",
  "imobiliária Ideal em Viçosa",
  "melhores imobiliárias em Viçosa",
  "imobiliária Ideal imóveis Viçosa",
  "imobiliária Ideal casas Viçosa",
  "imobiliária Ideal apartamentos Viçosa",
  "imobiliária Ideal terrenos Viçosa",
  "imobiliária Ideal lotes Viçosa",
  "imobiliária Ideal chácara Viçosa",
  "imobiliária Ideal cobertura Viçosa",
  "imobiliária Ideal galpão Viçosa",
  "imobiliária Ideal loja Viçosa",
  "imobiliária Ideal prédio Viçosa",
  "imobiliária Ideal sala Viçosa",
  "imobiliária Ideal sítio Viçosa",
  "imobiliária Ideal lote em condomínio Viçosa",
  "imobiliária Ideal compra e venda Viçosa",
  "imobiliária Ideal aluguel Viçosa",
  "consultoria imobiliária Ideal Viçosa",
  "imobiliária Ideal imóveis de qualidade Viçosa",
  "imobiliária Ideal atendimento personalizado Viçosa",
  "imobiliária Ideal para investidores Viçosa",
  "imobiliária Ideal imóveis para estudantes Viçosa",
  "imobiliária Ideal ofertas Viçosa",
  "imobiliária Ideal imóveis exclusivos Viçosa",
  "imobiliária Ideal casas e apartamentos Viçosa",
  "imobiliária Ideal imóveis comerciais Viçosa",
  "imobiliária Ideal imóveis residenciais Viçosa",
  "imobiliária Ideal Viçosa imóveis novos e usados",
  "imobiliária Ideal imóveis com financiamento Viçosa",
  "imobiliária Ideal Viçosa imóveis acessíveis",
  "imobiliária Ideal Viçosa imóveis para primeira compra",
  "imobiliária Ideal Viçosa imóveis para férias",
  "imobiliária Ideal Viçosa imóveis em áreas valorizadas",
  "imobiliária Ideal Viçosa imóveis em bairros tranquilos",
  "imobiliária Ideal Viçosa imóveis com lazer",
  "imobiliária em Viçosa",
  "imobiliária Viçosa",
  "melhores imobiliárias em Viçosa",
  "imobiliária Viçosa MG",
  "imobiliária Viçosa imóveis",
  "imobiliária Viçosa casas",
  "imobiliária Viçosa apartamentos",
  "imobiliária Viçosa terrenos",
  "imobiliária Viçosa lotes",
  "imobiliária Viçosa chácara",
  "imobiliária Viçosa cobertura",
  "imobiliária Viçosa galpão",
  "imobiliária Viçosa loja",
  "imobiliária Viçosa prédio",
  "imobiliária Viçosa sala",
  "imobiliária Viçosa sítio",
  "imobiliária Viçosa lote em condomínio",
  "aluguel imóveis Viçosa",
  "venda imóveis Viçosa",
  "compra imóveis Viçosa",
  "imobiliária Viçosa aluguel casas",
  "imobiliária Viçosa aluguel apartamentos",
  "imobiliária Viçosa venda terrenos",
  "imobiliária Viçosa compra lotes",
  "imobiliária Viçosa imóveis comerciais",
  "imobiliária Viçosa imóveis residenciais",
  "consultoria imobiliária Viçosa",
  "ofertas imobiliárias Viçosa",
  "imobiliária Viçosa para investidores",
  "imobiliária Viçosa imóveis de qualidade",
  "imobiliária Viçosa imóveis acessíveis",
  "imobiliária Viçosa imóveis novos e usados",
  "imobiliária Viçosa imóveis com financiamento",
  "imobiliária Viçosa imóveis para primeira compra",
  "imobiliária Viçosa imóveis para férias",
  "imobiliária Viçosa imóveis em áreas valorizadas",
  "imobiliária Viçosa imóveis em bairros tranquilos",
  "imobiliária Viçosa imóveis com lazer",
  "imobiliária Viçosa serviços imobiliários",
  "venda casa Viçosa",
  "venda apartamento Viçosa",
  "venda terreno Viçosa",
  "venda lote Viçosa",
  "venda chácara Viçosa",
  "venda cobertura Viçosa",
  "venda galpão Viçosa",
  "venda loja Viçosa",
  "venda sítio Viçosa",
  "venda lote em condomínio Viçosa",
  "aluguel casa Viçosa",
  "aluguel apartamento Viçosa",
  "aluguel terreno Viçosa",
  "aluguel lote Viçosa",
  "aluguel chácara Viçosa",
  "aluguel cobertura Viçosa",
  "aluguel galpão Viçosa",
  "aluguel loja Viçosa",
  "aluguel sítio Viçosa",
  "aluguel lote em condomínio Viçosa",
  "compra casa Viçosa",
  "compra apartamento Viçosa",
  "compra terreno Viçosa",
  "compra lote Viçosa",
  "compra chácara Viçosa",
  "compra cobertura Viçosa",
  "compra galpão Viçosa",
  "compra loja Viçosa",
  "compra sítio Viçosa",
  "compra lote em condomínio Viçosa",
];
export default tagsSeo;
