import { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import request from "request";

export const useHooks = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [favoritesList, setFavoritesList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const [type, setType] = useState(location.pathname?.replace("/", "") || "alugar");

  const [cities, setCities] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [types, setTypes] = useState([]);
  const [newForm, setNewForm] = useState({});
  const [filtersLoaded, setFiltersLoaded] = useState(false);

  const cidadePesquisada = queryParams.get("cidade");
  const bairroPesquisado = queryParams.get("bairro")?.split("+") || [];
  const tipoImovelPesquisado = queryParams.get("tipo")?.split("+") || [];
  const codigoImovelPesquisado = queryParams.get("codigo");

  const defaultFormValues = {
    city: cidadePesquisada || "1",
    neighborhood: bairroPesquisado,
    code: codigoImovelPesquisado || "",
    types: tipoImovelPesquisado,
    bedrooms: 1,
    bathrooms: 1,
    value: type === "alugar" ? [0, 2000000] : [0, 2000000],
  };

  const [form, setForm] = useState(defaultFormValues);

  const openProperty = (property) => {
    localStorage.clear();
    localStorage.setItem(
      "state",
      JSON.stringify({
        ...property,
        filters: { ...form, finalidade: type },
      })
    );

    window.open(
      `/detalhes/imovel/${property.finalidade}-${property.tipo}-${property.numeroquartos}-quartos-${property.bairro}-${property.cidade}-${property.estado}-${property.codigo}`
    );
  };

  const toggleArray = (value) => typeof value === "string" ? value.split(",") : value;

  const handleChange = (value, name) => {
    if (name === "types") {
      setForm(prevForm => ({
        ...prevForm,
        types: prevForm.types.includes(value)
          ? prevForm.types.filter(type => type !== value)
          : [...prevForm.types, value],
      }));
    } else {
      setForm(prevForm => ({
        ...prevForm,
        [name]: name === "neighborhood" ? toggleArray(value) : value,
      }));
    }
  };

  const parseFilter = useCallback((value) => {
    if (value && form.types.length === 0) return value;
    if (!value) return 0;
    let includeTypeToIgnore = false;
    const typesToIgnore = [6, 11, 12, 5, 3, 4];
    form.types.forEach(item => {
      if (typesToIgnore.includes(item)) {
        includeTypeToIgnore = true;
      }
    });
    return includeTypeToIgnore ? 0 : value;
  }, [form.types]);

  const getCitiesAndTypes = async () => {
    try {
      const resCities = await request("GET", "Imovel/RetornarCidadesDisponiveis");
      const resTypes = await request("GET", "Imovel/RetornarTiposImoveisDisponiveis");

      const cities = resCities?.lista?.map(item => ({
        value: item.codigo,
        option: `${item.nome}-${item.estado}`
      })) || [];

      const types = resTypes?.lista?.map(item => ({
        value: item.codigo,
        option: item.nome,
      })) || [];

      const vicosa = cities.filter(city => city?.option === "Viçosa-MG");
      const citiesWithoutVicosa = cities.filter(city => city?.option !== "Viçosa-MG");

      setCities([...vicosa, ...citiesWithoutVicosa]);
      setTypes(types);
      const cidadeCodigo = cidadePesquisada ? cities.find(city => city.option === cidadePesquisada) : vicosa[0];

      const tiposCodigos = tipoImovelPesquisado.map(tipo => {
        return types
          .filter(item => tipo === item.option)
          .map(item => item.value);
      }).flat();

      setForm(prevForm => ({
        ...prevForm,
        city: cidadeCodigo?.value || "1",
        types: tiposCodigos || []
      }));
    } catch (error) {
      console.error("Error fetching cities and types:", error);
    }
  };

  const getNeighborhood = useCallback(async () => {
    if (!form.city) return;

    try {
      const res = await request("GET", "Imovel/RetornarBairrosDisponiveis", {
        codigoCidade: form.city,
      });

      const neighborhood = res?.lista?.map(item => ({
        value: item.codigo,
        option: item.nome,
      })) || [];

      setNeighborhoods(neighborhood);

      const bairrosCodigos = bairroPesquisado.flatMap(bairro => {
        return neighborhood
          .filter(item => bairro === item.option)
          .map(item => item.value);
      });

      setForm(prevForm => ({
        ...prevForm,
        neighborhood: bairrosCodigos,
      }));

      setFiltersLoaded(true);
    } catch (error) {
      console.error("Error fetching neighborhoods:", error);
    }
  }, [form.city, bairroPesquisado]);

  const trocarCodigoNome = useCallback(() => {
    const cidade = cities.find(city => city.value === form.city);
    const bairrosSelecionados = neighborhoods.filter(item =>
      form.neighborhood.includes(item.value)
    );
    const nomeBairros = bairrosSelecionados.map(item => item.option);
    const tiposSelecionados = types.filter(item =>
      form.types.includes(item.value)
    );
    const nomeTiposImoveis = tiposSelecionados.map(item => item.option);

    const newForm = {
      ...(cidade && { cidade: cidade.option }),
      ...(nomeBairros.length > 0 && { bairros: nomeBairros }),
      ...(nomeTiposImoveis.length > 0 && { tiposImoveis: nomeTiposImoveis }),
      bedrooms: form.bedrooms,
      bathrooms: form.bathrooms,
      ...(form.code && { code: form.code }),
    };
    setNewForm(newForm);
  }, [cities, neighborhoods, form, types]);

  useEffect(() => {
    trocarCodigoNome();
  }, [cities, neighborhoods, form, trocarCodigoNome]);

  const handleQuery = useCallback(() => {
    if (!newForm) return;

    const params = {
      cidade: newForm.cidade || "",
      bairro: (newForm.bairros && newForm.bairros.length > 0) ? newForm.bairros.join("+") : "",
      tipo: (newForm.tiposImoveis && newForm.tiposImoveis.length > 0) ? newForm.tiposImoveis.join("+") : "",
      quartos: newForm.bedrooms || "",
      banheiros: newForm.bathrooms || "",
      codigo: newForm.code || "",
    };

    const queryParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        queryParams.append(key, value);
      }
    });

    const queryString = queryParams.toString();
    history.push(`/${type}?${queryString}`);
  }, [history, type, newForm]);

  useEffect(() => {
    if (filtersLoaded) {
      handleQuery();
    }
  }, [filtersLoaded, handleQuery]);

  const getProperties = useCallback(async () => {
    setLoading(true);

    try {
      const codigosBairrosNumeros = form.neighborhood.map(neighborhood => {
        const numero = Number(neighborhood);
        if (isNaN(numero)) {
          console.warn(`Valor não numérico encontrado em form.neighborhood: ${neighborhood}`);
          return null;
        }
        return numero;
      }).filter(numero => numero !== null);

      const properties = await request("GET", "Imovel/RetornarImoveisDisponiveis", {
        codigosimoveis: form.code,
        finalidade: type === "alugar" ? 1 : 2,
        numeroPagina: page,
        numeroRegistros: 20,
        codigoTipo: form.types.join(","),
        codigocidade: form.city,
        codigosbairros: codigosBairrosNumeros.join(","),
        numeroquartos: parseFilter(+form.bedrooms),
        numerobanhos: parseFilter(+form.bathrooms),
        valorde: form.value[0],
        valorate: form.value[1],
      });

      setTotalPages(Math.ceil(properties.quantidade / 20));
      setData(properties.lista);
    } catch (error) {
      console.error("Error fetching properties:", error);
    } finally {
      setLoading(false);
    }
  }, [form, page, type, parseFilter]);

  useEffect(() => {
    const initialType = location.pathname?.replace("/", "") || "alugar";
    // setType(initialType);
    getCitiesAndTypes();
  }, [location.pathname]);


  useEffect(() => {
    if (form.city) {
      getNeighborhood();
    }
  }, [form.city]);

  useEffect(() => {
    if (filtersLoaded) {
      getProperties();
    }
  }, [filtersLoaded, form.city, form.neighborhood, page, getProperties]);

  return {
    page,
    setPage,
    loading,
    favoritesList,
    setFavoritesList,
    totalPages,
    data,
    openProperty,
    handleChange,
    handleQuery,
    getProperties,
    cities,
    neighborhoods,
    types,
    form,
    setType,
  };
};