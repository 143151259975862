import React from "react";
import "./styles.css";

const Imagens = (props) => {
  const { data, onClick } = props;
  const handleClicKImage = (index) => {
    onClick(index);
  };
  return (
    <div className="images-container">
      {data.map((slide, index) => (
        <div
          onClick={() => handleClicKImage(index)}
          key={index}
          className="image"
        >
          <img src={slide.src} alt={slide.description} />
        </div>
      ))}
    </div>
  );
};

export default Imagens;
