import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import "./Styles.css";
import "./Home.css";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";

import { Header, Footer } from "Components";
import Announce from "./components/Announce";
import SearchByCode from "./components/SearchByCode";
import Section4 from "./components/Section4";
import Seo from "helpers/tools/Seo";

const Home = () => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("alugar");
  const [openCodeInputModal, setOpenCodeInputModal] = useState(false);
  const location = useLocation();
  const pathname = location.hash;

  const handleClose = () => {
    setOpen((open) => !open);
    window.location.hash = "#";
  };

  const handleCloseCodeInputModal = () => {
    setOpenCodeInputModal((open) => !open);
  };

  useEffect(() => {
    if (pathname === "#anunciar") {
      setOpen(true);
    }
  }, [pathname]);

  return (
    <>
      <Seo
        title="Ideal - Negócio Imobiliário"
        description=" Um jeito novo de morar,alugue ou compre seu imóvel bem fácil"
        titleOg="Ideal - Negócios Imobiliário"
      />
      <Header />
      <div className="home-container">
        <div id="section-1">
          <Section1
            type={type}
            setType={setType}
            setOpenCodeInputModal={setOpenCodeInputModal}
          />
        </div>
        <div id="section-2">
          <Section2 />
        </div>
      </div>
      <div id="section-4">
        <Section4 />
      </div>
      <div id="contato">
        <Footer />
      </div>
      <SearchByCode
        open={openCodeInputModal}
        handleClose={handleCloseCodeInputModal}
      />
    </>
  );
};

export default Home;
