import { useState } from 'react'

export const useHooks = () => {
  const [step, setStep] = useState(1)
  const [form, setForm] = useState({})
  const steps = [
    { label: '', value: 1 },
    { label: '', value: 2 },
    { label: '', value: 3 },
  ]

  const handleSubmit = (data) => {
    if (!data) return

    if (step < 3) {
      setStep((step) => step + 1)
    }

    const newData = { ...form, ...data }
    setForm(newData)

    if (step === 3) {
      // TODO: mandar esses dados pra algum lugar
    }
  }

  const handleBackStep = () => {
    if (step === 1) return

    setStep((step) => step - 1)
  }

  return { step, steps, handleSubmit, handleBackStep }
}
