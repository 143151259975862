import React from 'react'

import ScrollAnimation from 'react-animate-on-scroll'
import { IndicateModal } from '..'

import './styles.css'
import backgroundImage from 'assets/bg-red.png'

export const Section1 = () => {
  return (
    <div className='indicates-section1-container'>
      <img src={backgroundImage} alt='papel de parede' />
      <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
        <div className='indicates-section1-content'>
          <h1>
            Ganhe dinheiro indicando imóveis para <br />
            alugar ou vender.
          </h1>
          <h3>
            Indique imóveis para a Ideal Negócios Imobiliários e ganhe R$50,00
            por imóvel publicado em nosso site.
          </h3>
          <h3>
            Já se o imóvel for vendido, você ganha R$300,00, <br />
            além do valor de publicação.
          </h3>
          <IndicateModal />
        </div>
      </ScrollAnimation>
    </div>
  )
}
