import React from "react";

import { GrLocation } from "react-icons/gr";
import { BiHomeAlt } from "react-icons/bi";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { MdOutlineBed } from "react-icons/md";
import { BsCurrencyDollar } from "react-icons/bs";

import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import { getLabel } from "helpers/utils";
import "./Styles.css";

export const SelectField = ({
  label,
  value,
  handleChange,
  options,
  name,
  icon,
  showCheckbox = false,
  placeholder,
}) => {
  const getIcon = (iconName) => {
    if (iconName === "GrLocation")
      return (
        <GrLocation style={{ marginBottom: 12, marginRight: 5 }} size={25} />
      );
    if (iconName === "BiHomeAlt")
      return (
        <BiHomeAlt style={{ marginBottom: 12, marginRight: 5 }} size={25} />
      );
    if (iconName === "HiOutlineOfficeBuilding")
      return (
        <HiOutlineOfficeBuilding
          style={{ marginBottom: 12, marginRight: 5 }}
          size={25}
        />
      );
    if (iconName === "MdOutlineBed")
      return (
        <MdOutlineBed style={{ marginBottom: 12, marginRight: 5 }} size={25} />
      );
    if (iconName === "BsCurrencyDollar")
      return (
        <BsCurrencyDollar
          style={{ marginBottom: 12, marginRight: 5 }}
          size={25}
        />
      );
  };

  return (
    <Box className="selectField-box">
      {getIcon(icon)}
      <FormControl fullWidth>
        <InputLabel id="demo-multiple-name-label" className="selectField-label">
          {label}
        </InputLabel>

        {!showCheckbox ? (
          <Select
            className="selectField-form-input"
            value={value}
            label={label}
            disableUnderline
            name={name}
            onChange={handleChange}
          >
            {options.map((item) => (
              <MenuItem
                key={item.option}
                className="selectField-form-option"
                value={item.value}
              >
                {item.option}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Select
            className="selectField-form-input"
            value={value}
            renderValue={(selected) => getLabel(options, selected)}
            multiple={showCheckbox}
            label={label}
            placeholder={placeholder}
            disableUnderline
            name={name}
            onChange={handleChange}
          >
            {options.map((item) => (
              <MenuItem
                key={item.option}
                className="selectField-form-option"
                value={item.value}
              >
                {showCheckbox && (
                  <Checkbox
                    color="primary"
                    checked={value.indexOf(item.value) > -1}
                  />
                )}
                <ListItemText>{item.option}</ListItemText>
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </Box>
  );
};
