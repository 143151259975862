import React, { useEffect, useState, useCallback } from "react";

import "./Styles.css";
import ScrollAnimation from "react-animate-on-scroll";
import { Button } from "@material-ui/core";
import { SelectField } from "Components";
import { useHistory } from "react-router-dom";

import request from "request";
import values from "helpers/constants";
import bgImage from "assets/familia.jpg";
import { Col, Container, Row } from "react-bootstrap";

const Section1 = ({ setOpenCodeInputModal, type, setType }) => {
  const { push } = useHistory();

  const [form, setForm] = useState({
    city: "",
    neighborhood: [],
    type: [],
    bedrooms: "",
    value: "",
  });
  const [cities, setCities] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [types, setTypes] = useState([]);
  const [bedrooms] = useState([
    { value: 1, option: "1+" },
    { value: 2, option: "2+" },
    { value: 3, option: "3+" },
    { value: 4, option: "4+" },
  ]);

  const toggleArray = (value) => {
    return typeof value === "string" ? value.split(",") : value;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((form) => ({
      ...form,
      [name]: ["type", "neighborhood"].includes(name)
        ? toggleArray(value)
        : value,
    }));
  };

  const getCitiesAndTypes = async () => {
    const resCities = await request("GET", "Imovel/RetornarCidadesDisponiveis");
    const resTypes = await request(
      "GET",
      "Imovel/RetornarTiposImoveisDisponiveis"
    );

    const cities = resCities?.lista?.map((item) => ({
      value: item.codigo,
      option: `${item.nome}-${item.estado}`,
    }));

    const types = resTypes?.lista?.map((item) => ({
      value: item.codigo,
      option: item.nome,
    }));

    const vicosa = cities.filter((city) => {
      if (city?.option === "Viçosa-MG") return city;
      return undefined;
    });

    const citiesWithoutVicosa = cities.filter((city) => {
      if (city?.option !== "Viçosa-MG") return city;
      return undefined;
    });

    setCities([...vicosa, ...citiesWithoutVicosa]);
    setTypes(types);
  };

  const getNeighborhood = useCallback(async () => {
    if (!form?.city || form.city === "") return;

    const res = await request("GET", "Imovel/RetornarBairrosDisponiveis", {
      codigoCidade: form.city,
    });

    const neighborhood = res?.lista?.map((item) => ({
      value: item.codigo,
      option: item.nome,
    }));
    setNeighborhoods(neighborhood);
  }, [form.city]);

  const getPropertieValue = (form) => {
    if (form?.value !== "") return [0, form.value];

    if (type === "alugar") return [0, 200000];
    else return [0, 2000000];
  };

  const search = () => {
    const queryParams = new URLSearchParams({
      cidade: cities.find(city => city.value === form.city)?.option || "Viçosa-MG",
      bairro: neighborhoods.filter(neigh => form.neighborhood.includes(neigh.value)).map(neigh => neigh.option).join(",") || "",
      quartos: form.bedrooms || 1,
      banheiros: form.bathrooms || 1,
      valor: form.value || "",
      tipos: form.type.join(",") || ""
    }).toString();
    const url = `${type}?${queryParams}`;
    push(url);
  };

  useEffect(() => {
    getCitiesAndTypes();
  }, []);

  useEffect(() => {
    getNeighborhood();
  }, [form.city, getNeighborhood]);

  const isMobileScreen = () => {
    return window.innerWidth <= 600;
  };

  const [isMobile, setIsMobile] = useState(isMobileScreen());

  useEffect(() => {
    // Add an event listener to update isMobile when the window is resized
    const handleResize = () => {
      setIsMobile(isMobileScreen());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container text-center">
      <div className="row">
        {isMobile ? (
          <div className="col">
            <img src={bgImage} alt="familia" className="familyContainer" />
          </div>
        ) : null}
        <div className="col containerPage1">
          <div className="testando" style={{ position: "relative" }}>
            <div className="teste">
              <h1 className="tituloContainer">Um jeito novo de morar</h1>
              <h3 className="paragrafoContainer">
                Alugue ou compre seu imóvel bem fácil
              </h3>
              <div className="containerButton">
                <Button
                  onClick={() => {
                    setType("alugar");
                  }}
                  style={{
                    marginRight: 10,
                    width: 150,
                    fontSize: 14,
                    backgroundColor:
                      type === "alugar" ? "#FFB800" : "transparent",
                    color: type === "alugar" ? "#fff" : "#000",
                    border: "1px solid #FFB800",
                    marginRight: 20,
                  }}
                >
                  ALUGAR
                </Button>
                <Button
                  onClick={() => {
                    setType("comprar");
                  }}
                  style={{
                    width: 150,
                    border: "1px solid #FFB800",
                    backgroundColor:
                      type === "comprar" ? "#FFB800" : "transparent",
                    color: type === "comprar" ? "#fff" : "#000",
                  }}
                >
                  COMPRAR
                </Button>
              </div>
              <div className="selectContainer">
                <div className="centralizado">
                  <SelectField
                    style={{ marginTop: "5%" }}
                    options={cities}
                    value={form.city}
                    label="Selecionar cidade"
                    name="city"
                    handleChange={handleChange}
                    icon={"GrLocation"}
                  />

                  <SelectField
                    className="margins"
                    options={neighborhoods}
                    value={form.neighborhood}
                    label="Selecionar bairro"
                    name="neighborhood"
                    showCheckbox
                    handleChange={handleChange}
                    icon={"BiHomeAlt"}
                  />
                </div>
              </div>
              <div className="selectContainer">
                <div className="centralizado">
                  <SelectField
                    options={types}
                    value={form.type}
                    label="Selecionar tipo"
                    name="type"
                    showCheckbox
                    handleChange={handleChange}
                    icon={"HiOutlineOfficeBuilding"}
                  />

                  <SelectField
                    options={bedrooms}
                    value={form.bedrooms}
                    label="Selecionar nº quartos"
                    name="bedrooms"
                    handleChange={handleChange}
                    icon={"MdOutlineBed"}
                  />
                </div>
              </div>
              <div className="selectContainer">
                <div className="centralizado">
                  <SelectField
                    options={values[type]}
                    value={form.value}
                    label="Selecionar valor até"
                    name="value"
                    handleChange={handleChange}
                    icon={"BsCurrencyDollar"}
                  />
                </div>
              </div>
              <div>
                <div>
                  <Button
                    onClick={search}
                    style={{
                      width: 150,
                      backgroundColor: "#552a2b",
                      color: "#fff",
                      borderRadius: 50,
                      width: 300,
                      marginTop: 20,
                    }}
                  >
                    BUSCAR
                  </Button>
                </div>
                <div>
                  <p
                    className="buscarPeloCodigo"
                    onClick={() => {
                      setOpenCodeInputModal(true);
                    }}
                  >
                    Buscar pelo código
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isMobile ? null : (
          <div className="col">
            <img src={bgImage} alt="familia" className="familyContainer" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Section1;
