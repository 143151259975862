import React, { useEffect, useState } from "react";

import "./App.css";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";

import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { ptBR } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import Home from "views/Home";
import Rent from "views/Rent/Rent";
import Details from "views/Details/Details";
import Indicates from "views/Indicates/Indicates";
import Capture from "views/Capture/Capture";
import Favorites from "views/Favorites/Favorites";
import SimulateFinancing from "views/SimulateFinancing";
import StaticExample from "views/Details/components/globalModalWpp";
import Announce from "views/Home/components/Announce";
import AnuncieAqui from "views/Home/components/anuncieAqui";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7e1c1e",
    },
    secondary: {
      main: "#FFF",
    },
  },
  typography: {
    fontFamily: ["Helvetica Neue", "Roboto"],
  },
  overrides: {
    MuiMenuItem: {
      root: {
        color: "#333",
        fontWeight: "400",
        fontSize: 18,
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
    },
  },
});

function App() {
  const currentPath = window.location.pathname;
  const idMatch = currentPath.match(/\/(\d+)$/);
  const id = idMatch ? idMatch[1] : null;

  const [aberto, setAberto] = useState(false);

  const handleOpenMo = () => {
    setAberto(true);
  };

  useEffect(() => {
    if (aberto) {
    }
  }, [aberto]);

  const closeModal = () => {
    setAberto(!aberto);
  };

  return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <ThemeProvider theme={theme}>
          <div className="App">
            <div style={{ position: "relative" }}>
              <a onClick={handleOpenMo} class="float" target="_blank">
                <i class="fa fa-whatsapp my-float"></i>
              </a>
            </div>
            {aberto && (
              <StaticExample
                openModal={aberto}
                onClose={closeModal}
              ></StaticExample>
            )}
            <BrowserRouter>
              <Switch>
                <Route path="/" exact>
                  <Home />
                </Route>
                <Route path="/alugar" exact>
                  <Rent type="alugar" />
                </Route>
                <Route path="/comprar" exact>
                  <Rent type="comprar" />
                </Route>
                <Route path="/detalhes/imovel/:finalidade-:tipo-:numeroquartos-quartos-:bairro:cidade-:estado-:codigo" exact>
                  <Details />
                </Route>

                <Route path="/indica-ai" exact>
                  <Indicates />
                </Route>
                <Route path="/captacao" exact>
                  <Capture />
                </Route>
                <Route path="/favoritos" exact>
                  <Favorites />
                </Route>
                <Route path="/simule-financiamento" exact>
                  <SimulateFinancing />
                </Route>
                <Route path="/anunciar" exact>
                  <AnuncieAqui />
                </Route>
              </Switch>
            </BrowserRouter>
          </div>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
  );
}

export default App;
