import React, { useState } from 'react'

import { Button } from '@material-ui/core'
import './styles.css'

export const Step1 = ({ handleSubmit }) => {
  const [selected, setSelected] = useState('')
  const options = [
    { key: 1, option: 'Talvez eu compre em 6 meses ou mais' },
    { key: 2, option: 'Quero comprar entre 3 e 5 meses' },
    { key: 3, option: 'Quero comprar entre 1 e 2 meses' },
    { key: 4, option: 'Já comprei, falta financiar' },
  ]

  return (
    <div className='step1'>
      <h1>Nos deixe te conhecer melhor!</h1>
      <p>
        Os dados que pediremos a seguir são importantes para simularmos o seu
        financiamento. Vamos começar entendendo qual situação melhor se encaixa
        com a sua:
      </p>
      <div className='step1-options-container'>
        {options.map((item) => (
          <span
            className={`step1-option ${
              selected === item.option ? 'selected' : ''
            }`}
            onClick={() => {
              setSelected(item.option)
            }}
            key={item.key}
          >
            {item.option}
          </span>
        ))}
      </div>
      <div className='step1-button-container'>
        <Button
          onClick={() => handleSubmit({ situation: selected })}
          disabled={selected === ''}
          className={`step1-button ${selected === '' ? 'disabled' : ''}`}
        >
          Próximo
        </Button>
      </div>
    </div>
  )
}
