import React from 'react'

import ScrollAnimation from 'react-animate-on-scroll'

import './styles.css'
import person from 'assets/person.png'
import contact from 'assets/contact.png'
import money from 'assets/money.png'

export const Section2 = () => {
  const sectionItem = [
    {
      title: 'Rápido e fácil',
      text: (
        <p>
          1- Preencha o formulário <br />
          com seu nome e dados do <br />
          proprietário.
        </p>
      ),
      imgSrc: person,
      imgAlt: 'Pessoa',
      animate: 'bounceInRight',
      reverse: true,
    },
    {
      title: 'Ligaremos para você caso o imóvel seja aprovado',
      text: (
        <p>
          2- Entraremos e contato, confirmamos o interesse do proprietário em
          colocar o seu imóvel em oferta na Ideal Negócios Imobiliários,
          visitamos o imóvel e tiramos fotos profissionais e publicamos o
          imóvel.
        </p>
      ),
      imgSrc: contact,
      imgAlt: 'Contato',
      animate: 'bounceInLeft',
    },
    {
      title: 'Dinheiro no seu Bolso!',
      text: (
        <p>
          3- O valor referente à indicação caso aprovada e publicada no site
          deve ser retirado nas lojas da Ideal Negócios Imobiliários, ou por via
          de depósito bancário que será realizado sempre até o dia 30 do mês
          subsequente a aprovação da indicação.
        </p>
      ),
      imgSrc: money,
      imgAlt: 'Dinheiro',
      animate: 'bounceInRight',
      reverse: true,
    },
  ]
  return (
    <section className='indicates-section2'>
      <div className='indicates-section2-content'>
        {sectionItem.map((item) => (
          <ScrollAnimation
            key={item.title}
            animateIn={item.animate}
            animateOnce={true}
          >
            <article
              style={{ flexDirection: item.reverse ? 'row-reverse' : 'row' }}
              className='indicates-section2-content-item'
            >
              <img src={item.imgSrc} alt={item.imgAlt} />
              <div className='indicates-section2-content-item-texts'>
                <h2>{item.title}</h2>
                {item.text}
              </div>
            </article>
          </ScrollAnimation>
        ))}
      </div>
    </section>
  )
}
