import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import {
  Captions,
  Download,
  Fullscreen,
  Thumbnails,
  Zoom,
} from "yet-another-react-lightbox/plugins";
import Imagens from "./imagens";

function Gallery({ data }) {
  const [index, setIndex] = useState(-1);

  const slides = data.map((item, index) => ({
    src: item.url,
    description: item.descricao,
    title: `Foto ${index + 1}`,
  }));

  return (
    <>
      <div className="gallery-container">
        <div className="gallery-inner">
          <Imagens
            data={slides}
            onClick={(currentIndex) => setIndex(currentIndex)}
          />
          <Lightbox
            plugins={[Captions, Download, Fullscreen, Thumbnails]}
            captions={{
              showToggle: true,
              descriptionTextAlign: "center",
            }}
            index={index}
            open={index >= 0}
            slides={slides}
            close={() => setIndex(-1)}
          />
        </div>
      </div>
    </>
  );
}

export default Gallery;
