import { useState, useEffect } from 'react'

import request from 'request'
import validate from 'validate.js'
import swal from 'sweetalert'

const schema = {
  name: {
    presence: { allowEmpty: false, message: () => 'Este campo é obrigatório' },
    length: { maximum: 100, tooLong: 'Máximo de 100 caracteres' },
  },
  phone: {
    presence: { allowEmpty: false, message: () => 'Este campo é obrigatório' },
    length: { is: 11, message: 'Insira um número válido' },
  },
  propertyOwnerName: {
    presence: { allowEmpty: false, message: () => 'Este campo é obrigatório' },
    length: { maximum: 100, tooLong: 'Máximo de 100 caracteres' },
  },
  propertyOwnerPhone: {
    presence: { allowEmpty: false, message: () => 'Este campo é obrigatório' },
    length: { is: 11, message: 'Insira um número válido' },
  },
  fullAddress: {
    presence: { allowEmpty: false, message: () => 'Este campo é obrigatório' },
    length: { maximum: 100, tooLong: 'Máximo de 100 caracteres' },
  },
}

const initialFormState = {
  isValid: false,
  values: {
    name: '',
    phone: '',
    propertyOwnerName: '',
    propertyOwnerPhone: '',
    fullAddress: '',
  },
  touched: {
    name: false,
    phone: false,
    propertyOwnerName: false,
    propertyOwnerPhone: false,
    fullAddress: false,
  },
  errors: {
    name: [],
    phone: [],
    propertyOwnerName: [],
    propertyOwnerPhone: [],
    fullAddress: [],
  },
}

export const useHooks = () => {
  const [form, setForm] = useState(initialFormState)

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setForm(initialFormState)
    setOpen(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!form.isValid)
      return setForm((form) => ({
        ...form,
        touched: {
          ...form.touched,
          name: true,
          phone: true,
          propertyOwnerName: true,
          propertyOwnerPhone: true,
          fullAddress: true,
        },
      }))

    try {
      await request('POST', '/email/indication', {
        name: form.values.name,
        cellphone: form.values.phone,
        owner: form.values.propertyOwnerName,
        ownerCellphone: form.values.propertyOwnerPhone,
        address: form.values.fullAddress,
      })

      swal({
        icon: 'success',
        title: 'Imóvel indicado com sucesso!',
        timer: 1500,
      })
      handleClose()
    } catch (ex) {
      swal({
        icon: 'error',
        title: 'Aconteceu algum erro ao indicar o imóvel',
        timer: 1500,
      })
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setForm((form) => ({
      ...form,
      values: {
        ...form.values,
        [name]: ['phone', 'propertyOwnerPhone'].includes(name)
          ? value.replace(/\D/g, '')
          : value,
      },
      touched: {
        ...form.touched,
        [name]: true,
      },
    }))
  }

  useEffect(() => {
    const errors = validate(form.values, schema, { fullMessages: false })
    setForm((form) => ({
      ...form,
      isValid: !errors,
      errors: errors || {},
    }))
  }, [form.values, form.touched])

  const hasError = (name) => Boolean(form?.touched[name] && form?.errors[name])

  return {
    form,
    handleOpen,
    handleClose,
    open,
    handleSubmit,
    handleChange,
    hasError,
  }
}
