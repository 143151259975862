import React, { useState } from 'react'

import { FiInfo } from 'react-icons/fi'
import './styles.css'

const Info = ({ text }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className='info-container'>
      <div className={open ? 'baloon' : 'hide'}>{text}</div>
      <div
        className='info-icon'
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <FiInfo color='#1c1c1c' size={19} />
      </div>
    </div>
  )
}

export default Info
