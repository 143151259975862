import { useState, useCallback, useEffect } from "react";
import request from "request";
import swal from "sweetalert";
import { getNumberFromValue } from "helpers/utils";

import { useHistory } from "react-router-dom";

export const useHooks = (currentProperty) => {
  const { location } = useHistory();

  const [favoritesList, setFavoritesList] = useState([]);
  const [attempt, setAttempt] = useState(0);
  const [filter, setFilter] = useState(location.state?.filters);
  const [data, setData] = useState([]);

  const openProperty = (property) => {
    window.open(
      `detalhes/imovel/${property.finalidade}-${property.tipo}-${property.numeroquartos}-quartos-${property.bairro}-${property.cidade}-${property.estado}-${property.codigo}`
    );
    window.scrollTo(0, 0);
  };

  const addFavorite = (property) => {
    const newFavorites = favoritesList.length > 0 ? [...favoritesList] : [];
    newFavorites.push(`${property?.codigo}`);
    localStorage.setItem("favorites", newFavorites.join(","));
    setFavoritesList(newFavorites);
    swal({
      title: "Imóvel adicionado aos favoritos",
      icon: "success",
      timer: 1500,
    });
  };

  const removeFavorite = (property) => {
    const newFavorites = favoritesList.length > 0 ? [...favoritesList] : [];
    newFavorites.splice(newFavorites.indexOf(`${property.codigo}`), 1);
    localStorage.setItem("favorites", newFavorites.join(","));
    setFavoritesList(newFavorites);
  };

  const getProperties = useCallback(async () => {
    const getPurpose = () => {
      if (filter?.finalidade) {
        return filter?.finalidade === "alugar" ? 1 : 2;
      }
      const valor = getNumberFromValue(currentProperty?.valor);
      return +valor < 3000 ? 1 : 2;
    };

    const properties = await request(
      "GET",
      "Imovel/RetornarImoveisDisponiveis",
      {
        finalidade: getPurpose(),
        numeroPagina: 1,
        numeroRegistros: 20,
        codigoTipo: filter?.types?.join(",") ?? "",
        codigocidade: filter?.city ?? currentProperty?.codigocidade ?? 0,
        codigosbairros:
          filter?.neighborhood?.join(",") ??
          currentProperty?.codigosbairros?.join(",") ??
          "",
        numeroquartos: filter?.bedrooms ? +filter?.bedrooms : 1,
        numerobanhos: filter?.bathrooms ? +filter?.bathrooms : 1,
        valorde: filter?.value[0] ?? 0,
        valorate: filter?.value[1] ?? 200000,
      }
    );

    if (attempt < 4 && properties?.lista?.length < 1) {
      if (attempt === 0) {
        setFilter((filter) => ({
          ...filter,
          value: [0, 2000000],
        }));
      }
      if (attempt === 1) {
        setFilter((filter) => ({
          ...filter,
          types: [""],
        }));
      }
      if (attempt === 2) {
        setFilter((filter) => ({
          ...filter,
          neighborhood: "",
        }));
      }
      if (attempt === 3) {
        setFilter((filter) => ({
          ...filter,
          city: "",
        }));
      }
      setAttempt((attempt) => attempt + 1);
      return;
    }
    const currentPropertyValue = parseInt(currentProperty.valor.replace(/\D/g, '')) || 0;
    
    const trintaPorcentoAmais = currentPropertyValue * 1.3;
    const trintaPorcentoAmenos= currentPropertyValue * 0.70; 
    
    if (currentProperty?.finalidade === "Aluguel") {
      const similarProperties = properties?.lista?.filter(
        (item) => {
          const itemValue = parseInt(item.valor.replace(/\D/g, '')) || 0;
          return (
            item?.codigo !== currentProperty?.codigo &&
            itemValue >= trintaPorcentoAmenos && 
            itemValue <= trintaPorcentoAmais 
          );
        }
      );
      setData(similarProperties);
      setAttempt(0);
    } else {
      const similarProperties = properties?.lista?.filter(
        (item) => {
          const itemValue = parseInt(item.valor.replace(/\D/g, '')) || 0;
          return (
            item?.codigo !== currentProperty?.codigo &&
            itemValue >= trintaPorcentoAmenos && 
            itemValue <= trintaPorcentoAmais 
          );
        }
      );
      setData(similarProperties);
      setAttempt(0);
    }
    
  }, [attempt, filter, currentProperty]);

  useEffect(() => {
    getProperties();
  }, [getProperties, filter]);

  useEffect(() => {
    const favorites = localStorage.getItem("favorites") || "";
    setFavoritesList(favorites?.split(","));
  }, []);

  return { data, openProperty, addFavorite, removeFavorite, favoritesList };
};
