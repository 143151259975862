import React from "react";
import { useHooks } from "./hooks";

import ReactInputMask from "react-input-mask";
import moment from "moment";
import "moment/locale/pt-br";

import "./styles.css";
import { FiX } from "react-icons/fi";
import {
  Modal,
  Button,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";

import { DatePicker } from "@material-ui/pickers";
import Form from "react-bootstrap/Form";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export const ScheduleModal = ({ code, finalidade }) => {
  const {
    form,
    open,
    handleChange,
    handleClose,
    handleOpen,
    loading,
    hoursOptions,
    setStep,
    step,
    steps,
    handleCancel,
    handleSchedule,
    hasError,
  } = useHooks(code, finalidade);

  const TimePicker = () => (
    <div className="">
      <div className="">
        <h2 style={{color: '#570605'}}>Horários disponíveis:</h2>
        {loading ? (
          <CircularProgress />
        ) : (
          <div
            className="divsForms"
          >
            <div className="dateInput">
              <DatePicker
              className="dates"
                value={form.values.date}
                // label="Data desejada"
                minDate={new Date()}
                onChange={(date) => {
                  handleChange("date", date);
                }}
                // variant="static"
              />
            </div>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Horários</InputLabel>

              <Select
                aria-label="Default select example"
                label="Horários" // Adicione o rótulo aqui
                value={form.values.hour}
                onChange={(e) => handleChange("hour", e.target.value)} // Certifique-se de usar o campo correto, talvez seja 'hour' ao invés de 'ahoure'
              >
                {hoursOptions.length > 0 ? (
                  hoursOptions.map((hour) => (
                    <MenuItem key={hour} value={hour}>
                      {hour}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    Nenhum horário disponível
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
        )}
        <div className="schedule-buttons-container" style={{ marginTop: 20 }}>
          <Button onClick={handleCancel} className="secondary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              setStep("contact");
            }}
            disabled={form.values.hour?.length === 0}
            style={{ marginLeft: 10 }}
          >
            Próximo
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    finalidade !== "Aluguel" && (
      <>
        <Button onClick={handleOpen} className="schedule-button">
          Solicitar visita
        </Button>
        <Modal open={open} onClose={handleClose}>
          <div className="schedule-modal">
            <div className="schedule-modal-content-container">
              <header className="schedule-modal-header">
                <h1>Marcar visita</h1>
                <IconButton onClick={handleClose}>
                  <FiX size={25} color="#fff" />
                </IconButton>
              </header>
              <div className="schedule-modal-content">
                <Stepper
                  className="schedule-modal-stepper"
                  activeStep={step === "date" ? 0 : 1}
                  alternativeLabel
                >
                  {steps.map((item) => (
                    <Step key={item.label}>
                      <StepLabel>{item.label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {step === "date" && <TimePicker />}
                {step === "contact" && (
                  <div className="schedule-contacts">
                    <form onSubmit={handleSchedule}>
                      <TextField
                        error={hasError("fullName")}
                        helperText={
                          hasError("fullName") && form.errors["fullName"][0]
                        }
                        name="fullName"
                        className="input"
                        variant="outlined"
                        fullWidth
                        value={form.values.fullName}
                        label="Nome Completo"
                        onChange={(e) => {
                          handleChange("fullName", e.target?.value);
                        }}
                      />
                      <TextField
                        error={hasError("email")}
                        helperText={
                          hasError("email") && form.errors["email"][0]
                        }
                        name="email"
                        className="input"
                        variant="outlined"
                        fullWidth
                        value={form.values.email}
                        label="Email"
                        onChange={(e) => {
                          handleChange("email", e.target?.value);
                        }}
                      />
                      <ReactInputMask
                        mask={"(99) 99999-9999"}
                        value={form.values.phone}
                        onChange={(e) => {
                          handleChange(
                            "phone",
                            e.target?.value?.replace(/\D/g, "")
                          );
                        }}
                        maskChar={""}
                      >
                        {() => (
                          <TextField
                            error={hasError("phone")}
                            helperText={
                              hasError("phone") && form.errors["phone"][0]
                            }
                            name="phone"
                            className="input"
                            variant="outlined"
                            label="Celular"
                          />
                        )}
                      </ReactInputMask>

                      <div>
                        <label className="schedule-modal-radio-label">
                          Como prefere ser retornado?
                        </label>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={form.values.returnType}
                          onChange={(e) => {
                            handleChange("returnType", e.target?.value);
                          }}
                          row
                        >
                          <FormControlLabel
                            value="phone"
                            control={<Radio />}
                            label="Telefone"
                          />
                          <FormControlLabel
                            value="email"
                            control={<Radio />}
                            label="E-mail"
                          />
                          <FormControlLabel
                            value="whatsapp"
                            control={<Radio />}
                            label="Whatsapp"
                          />
                        </RadioGroup>
                      </div>
                      <div className="schedule-buttons-container">
                        <Button onClick={handleCancel} className="secondary">
                          Cancelar
                        </Button>
                        <Button
                          type="submit"
                          disabled={form.hour?.length === 0}
                          style={{ marginLeft: 10 }}
                        >
                          Agendar
                        </Button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  );
};
