import React from 'react'

import './styles.css'
import { RegulationModal } from './RegulationModal'

export const Section3 = () => {
  return (
    <div className='indicates-section3-container'>
      <p>
        4 - Se preferir Envie seu link para o proprietário e ele faz o cadastro
        incluindo os dados do imóvel e seu telefone
      </p>
      <RegulationModal />
    </div>
  )
}
