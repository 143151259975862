import React from "react";

import ScrollAnimation from "react-animate-on-scroll";

import "./Styles.css";
import busque from "assets/busque.png";
import agende from "assets/agende.png";
import feche from "assets/feche.png";
import cuidamos from "assets/cuidamos.png";
import lupa from "../../../../assets/lupa.svg";
import agenda from "../../../../assets/agenda.svg";
import aperto from "../../../../assets/aperto.svg";
import maoCoracao from "../../../../assets/maoCoracao.svg";

const Section2 = () => {
  const sectionItem = [
    {
      title: "Busque pelo imóvel",
      icon: lupa,
      text: (
        <p>
          Descubra qual o tipo de imóvel <br /> que mais combina com você!
        </p>
      ),
      imgSrc: busque,
      imgAlt: "Busque",
      animate: "bounceInLeft",
    },
    {
      title: "Agende com um de nossos corretores",
      icon: agenda,
      text: (
        <p>
          Somos especialistas em venda e locação, estamos aqui para te ajudar na
          sua melhor escolha!
        </p>
      ),
      imgSrc: agende,
      imgAlt: "Agendas",
      animate: "bounceInRight",
      reverse: true,
    },
    {
      title: "Feche o negócio",
      icon: aperto,
      text: (
        <p>Agora é só pegar sua chave e comemorar! Rápido, fácil e online!</p>
      ),
      imgSrc: feche,
      imgAlt: "Mãos apertando",
      animate: "bounceInLeft",
    },
    {
      title: "Cuidamos de todo o processo para você",
      icon: maoCoracao,
      text: (
        <p>
          E você não precisa se preocupar com a parte burocrática, pois nós{" "}
          <br />
          cuidamos disso pra você!
        </p>
      ),
      imgSrc: cuidamos,
      imgAlt: "Segurança",
      animate: "bounceInRight",
      reverse: true,
    },
  ];
  return (
    <section className="section-2">
      <div className="section2-content">
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <h1 className="section2-title">Veja como é fácil e sem burocracia</h1>
        </ScrollAnimation>
        <div className="containerCard">
          {sectionItem.map((item) => (
            <div className="cardItem">
              <div>
                <img className="svgItens" src={item.icon} alt={item.title} />
              </div>
              <div className="titleCard">
                <h4>{item.title}</h4>
              </div>
              <div className="textCard">
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section2;
